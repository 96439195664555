import React from 'react'
import img1 from '../../../../assets/Images/Tech/ai.jpg'
import img2 from '../../../../assets/Images/Tech/full stack.png'
import img3 from '../../../../assets/Images/Tech/logo-devops.png'
import imm from '../../../../assets/Images/Tech/enterv.webp'
const Technologies = () => {
  return (
    <div>
      <section className="min-h-screen grid grid-cols-4 lg:grid-cols-12 gap-14 max-w-screen-xl mx-auto py-10 px-7 md:px-16 pt-20">
  <div className="col-span-4 lg:col-span-5 ">
    <div className="relative h-full">
      <img
        src={imm}
        alt="Enterprise"
        className="w-full rounded-xl shadow-md md:rounded-2xl md:shadow-lg h-48 md:h-72 lg:h-full object-cover"
      />
      <div className="absolute bottom-0 text-white bg-gradient-to-b from-transparent to-gray-700 rounded-2xl h-auto p-5 md:p-10">
        <span className="mb-2 inline-block text-sm font-light">
        Enterprise Software Solutions
        </span>
        <p className="text-sm leading-relaxed">
        Whether it's tailoring purpose-driven solutions, infusing innovation into every aspect, or redefining user engagement at an enterprise scale, our Innovative Enterprise Software Solutions at GoDigitify ensure your digital solutions leave an enduring and meaningful impact on your enterprise objectives.

        </p>
      </div>
    </div>
  </div>
  <div className="col-span-4 lg:col-span-7 flex flex-col justify-center">
    <span className="inline-block mb-3 uppercase text-sm tracking-wide font-medium text-gray-900">
      Tools and Tecnology{" "}
    </span>
    <h1 className="text-4xl md:text-5xl md:leading-tight font-medium text-gray-900 xl:max-w-full">
      We Used Latest{" "}
      <span className="text-purple-900">Tools And Technology</span> for Enterprise Software Solutions
    </h1>
    <div className="grid gap-10 lg:max-w-xl pt-10 md:pt-14">
      <div className="sm:flex gap-6 items-center">
        <img
          src={img2}
          alt="Esther Howard"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
          Full-Stack Development Expertise
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          Leverage our full-stack development expertise for comprehensive solutions that cater to every facet of your enterprise needs.
          </p>
        </div>
      </div>
      <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
        <img
          src={img1}
          alt="Eleanor Pena"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
          AI-Powered Intelligence:
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          Elevate your enterprise software with cutting-edge artificial intelligence. Utilize state-of-the-art technologies like TensorFlow.js for in-depth machine learning integration.
          </p>
        </div>
      </div>
      <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
        <img
          src={img3}
          alt="Dianner Russell"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
          Operational Efficiency with DevOps
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          Optimize your enterprise software development process with DevOps practices. Streamline collaboration, automate workflows, and ensure faster, more reliable delivery of custom software solutions.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
      
    </div>
  )
}

export default Technologies
