import React from 'react'
import img from '../../../assets/careers-v.png'
const CareerSomethingBiggerSection = () => {
    return (
        <>
            <div className='bg-gradient-to-r from-[#00112D] to-black py-12'>
                <div className='text-gray-600 body-font px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl '>
                    <h1 className="mt-6 text-3xl font-normal text-white sm:mt-10 sm:text-3xl lg:text-4xl xl:text-6xl" >
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">

                            More than a Job, be a part of something bigger,
                        </span>{' '}
                        and have fun doing.
                    </h1>
                    <div className="md:flex w-full py-12">
                        <div className='md:w-3/5 '>
                        <img className="flex-shrink-0   w-full  h-96 object-contain  "
          src={img} alt=''/>

                        </div>
                        <div className='md:w-2/5 self-center  h-full'>
                            <p className='text-white'>Caring is at the heart of who we are, it makes us better colleagues, drives us to want excellence and connects us to Nagarro, transforms us into what we call a Nation of Nagarrians.

</p>
                        </div>
                    </div>
                    <div className="flex text-white ">
                        <p>The only certain thing is your starting point, the rest of the journey we build together. Caring for us means giving you the tools to succeed, the means to always learn more and the freedom to live your life without being worried about that appointment you have in the middle of the day. You are not an employee, you are our colleague and the journey starts here.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerSomethingBiggerSection
