import React from 'react'
import image from '../../assets/Images/branding.avif'
import image1 from '../../assets/Images/software.jpg'
const Section3 = () => {
    return (
        <div>
            <section className=" ">
                <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                    {/* Row */}
                    <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                        <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                            <h2 className="mb-4 text-3xl font-medium tracking-tight  text-[#462268]">
                                Streamline Your Development Workflow
                            </h2>
                            <p className="mb-8 text-gray-800 lg:text-xl font-semibold">
                                Embrace continuous integration and deployment, simplify knowledge management, and eliminate ITSM complexities

                            </p>
                            {/* List */}
                            <ul
                                role="list"
                                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
                            >
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight">
                                        Continuous integration and deployment
                                    </span>
                                </li>
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight">
                                        Development workflow
                                    </span>
                                </li>
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight">
                                        Knowledge management
                                    </span>
                                </li>
                            </ul>
                            <p className="mb-8 font-semibold lg:text-xl">
                                Deliver Exceptional Service Experiences with Ease.
                            </p>
                        </div>
                        <img
                            className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                            src={image1}
                            alt="dashboard feature image"
                        />
                    </div>
                    {/* Row */}
                    <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                        <img
                            className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                            src={image}
                            alt="feature image 2"
                        />
                        <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                            <h2 className="mb-4 text-3xl font-medium tracking-tight  text-[#462268]">
                                We empower brand innovation globally.

                            </h2>
                            <p className="mb-8 text-gray-800 font-semibold lg:text-xl">
                                Craft compelling brand experiences effortlessly – without the complexity of traditional strategies. Accelerate creative development, eliminate hassle, and implement changes seamlessly.

                            </p>
                            {/* List */}
                            <ul
                                role="list"
                                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
                            >
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight ">
                                        Dynamic reports and dashboards
                                    </span>
                                </li>
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight ">
                                        Templates for everyone
                                    </span>
                                </li>
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight ">
                                        Development workflow
                                    </span>
                                </li>
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight ">
                                        Limitless business automation
                                    </span>
                                </li>
                                <li className="flex space-x-3">
                                    {/* Icon */}
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-800"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="text-base font-medium text-gray-600 leading-tight ">
                                        Knowledge management
                                    </span>
                                </li>
                            </ul>
                            <p className="font-semibold lg:text-xl">
                                Deliver great service experiences fast - without the complexity of
                                traditional ITSM solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Section3
