import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../components/LandingPageComponents/keyfeatures.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const keyFeaturesData = [
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/healthcare-icon.png',
    title: 'Healthcare',
    description: 'Embark on a transformative journey in healthcare technology where the focus is on you—your patients, your practice, and your progress.',
  },
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/financial-icon.png',
    title: 'Banking & Financial',
    description: 'Get commitment to excellence and pioneer innovation in the financial industry where each step is a stride.',
  },
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/retail-icon.png',
    title: 'Retail',
    description: 'Set your brand apart in the competitive retail landscape and transform your vision into a unique, tech-driven retail experience.',
  },
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/legal-icon.png',
    title: 'Legal & Compliance',
    description: 'Choose excellence; choose innovation and craft solutions uniquely aligned with the needs of the legal and compliance landscape.',
  },
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/supply-icon.png',
    title: 'Supply Chain',
    description: 'Transform your operations and embark on a journey of efficiency with services designed for the dynamic landscape of supply chain industry.',
  },
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/energy-icon.png',
    title: 'Energy & Utilities',
    description: 'Embrace innovation in the Energy & Utilities sector with our specialized IT expertise, designed to power up your industry experience.',
  },
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/medical-icon.png',
    title: 'Medical Devices',
    description: 'Elevate your medical technology that seamlessly integrate into the intricacies of healthcare, ensuring efficiency and patient care.',
  },
  {
    imageUrl: 'https://mamsys.com/wp-content/themes/mamsys/images/manufacturing-icon.png',
    title: 'Manufacturing',
    description: 'Experience the power of technology-driven manufacturing transformation, designed to enhance every aspect of your industry operations.',
  },


];

const Industry = () => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleButtonClick = direction => {
    if (direction === 'prev') {
      sliderRef.current.slickPrev();
    } else if (direction === 'next') {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="key-features-slider-container mgh py-12 bg-[#00112d] text-white">
      <div className="container">
        <p className='lg:text-5xl md:3xl sm:2xl text-4xl text-center font-medium'>
          <span className='text-purple-900'>Industries We</span> Cater
        </p>
        
      </div>
      <style>{'body { overflow-x: hidden; }'}</style>
      
      <Slider ref={sliderRef} {...settings}>
        {keyFeaturesData.map((slide, index) => (
          <div key={index} style={{ padding: '0', height: '100%' }}>
            <div className="premium__slide slick-slide slick-current slick-active custom-height border-0  text-white hover:text-black" style={{ width: '100%', height: '100%' }}>
              <div className="border-0" style={{ height: '100%' }}>
                <div className="uar-inner-section-box opacity-80 hover:opacity-100 " style={{height:"100%", borderTop:"none", borderLeft:"none", borderRight:"none", borderBottom:"10px solid darkpurple"}}>
                  <div className="uar-section-content uar-grid-col-01 border-0">
                    
                      <div className="p-2">
                        <img src={slide.imageUrl} alt="" srcSet="" />
                      </div>
                      <h2 className="font-medium text-3xl md:text-2xl my-3">{slide.title}</h2>
                      <div>
                        
                      </div>
                      <div className="pb-5">
                        <p>{slide.description}</p>
                      </div>
                  </div>
                  <div className="uar-section-content uar-grid-col-02"></div>
                  <Link to='/services'>
                    <FontAwesomeIcon icon={faArrowRight} className=' transition transform duration-300 hover:scale-150 row-start-10' />
                  </Link>
                </div>
              </div>           
            </div>       
          </div>
        ))}
       
      </Slider>
      <div className="w-full m-auto mt-8">
        <div className="flex justify-center gap-10 ">
          <div
            className=" p-3 w-10 h-10 justify-center cursor-pointer bg-purple-900 hover:bg-white rounded-full flex item-center"
            onClick={() => handleButtonClick('prev')}
          >
            <FontAwesomeIcon icon={faArrowLeft} className='text-white hover:text-purple-900 ' />
          </div>
          <div
            className=" p-3 w-10 h-10 justify-center cursor-pointer bg-purple-900 hover:bg-white rounded-full flex item-center"
            onClick={() => handleButtonClick('next')}
          >
            <FontAwesomeIcon icon={faArrowRight} className='text-white hover:text-purple-900' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industry;
