import React from 'react'

const ShortDesc = () => {
  return (
    <div>
    <div className="w-10/12 mx-auto">
      <p className="text-justify pt-40  pb-40 text-grey" id="des-text">
        Welcome to Godigitify Nexus, your gateway to a realm where software
        solutions transcend traditional boundaries. At Godigitify Nexus, we
        pride ourselves on crafting exceptional and tailor-made software
        solutions that are not just lines of code but strategic assets
        propelling your business forward.
      </p>
    </div>
  </div>
  )
}

export default ShortDesc