import React from "react";

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, we weave innovation into every pixel through our Digital Design services, creating visually compelling and user-centric experiences that elevate your brand in the digital realm.

        </h2>
        <p className="pt-10">
          <strong>Consistent Branding Across Platforms</strong> <br />
          Maintain a cohesive brand presence on all digital platforms, reinforcing your identity through a consistent visual language for websites, apps, and social media.

        </p>
        <p className="pt-4">
          <strong>Responsive Web and App Design</strong> <br />
          Craft responsive designs for optimal user experiences across devices, ensuring adaptability and functionality on smartphones, tablets, and desktops.

        </p>
        <p className="pt-4">
          <strong>Motion Graphics for Dynamic Engagement</strong> <br />
          Integrate dynamic motion graphics to enhance digital content, adding visual appeal and interactivity for a more engaging user experience.

        </p>
      </div>
    </div>
  );
};

export default Description;
