import React, { useState } from 'react';

const Projects = () => {
    const [filter, setFilter] = useState('All');

    const projects = [
        {
            projectName: "SPS Jaitu, Social Media Handling",
            teamMembers: ["Mantasha Tabassum", "Ronit Jaiprakash", "Laxmi", "Harshit"],
            amount: "10,000/Month",
            startDate: "05 June",
            status: "Ongoing",
            link: "https://www.instagram.com/shivalikpublic_school/"
        },
        {
            projectName: "SKS Jaitu Social Media Handling",
            teamMembers: ["Anam Rashid"],
            amount: "3,000/Month",
            startDate: "05 June",
            status: "Ongoing",
            link: "https://www.instagram.com/shivalikkids/"
        },
        {
            projectName: "AIS Jaitu Social Media Handling",
            teamMembers: ["Muntaha Tabassum"],
            amount: "3,000/Month",
            startDate: "05 June",
            status: "Ongoing",
            link: "https://www.instagram.com/ais_jaitu/"
        },
        {
            projectName: "Abroad Educares Website",
            teamMembers: ["Aditya Jadon", "Parveen", "Naveen"],
            amount: "",
            startDate: "16 December 2022",
            status: "Completed",
            link: "https://abroadeducares.com/AbroadEduCares/"
        },
        {
            projectName: "AIS Banur Social Media Handling",
            teamMembers: ["Ronit Jaiprakash", "Mantasha Tabassum"],
            amount: "10,000/Month",
            startDate: "13 October 2023",
            status: "Ongoing",
            link: "https://www.instagram.com/schoolallianceinternational/"
        },
        {
            projectName: "Abroad Educares Social Media Handling",
            teamMembers: ["Anam Rashid"],
            amount: "2,000/monthly",
            startDate: "July 29, 2023",
            status: "Ongoing",
            link: ""
        },
        {
            projectName: "SVIET Website",
            teamMembers: ["Rishikesh Jha"],
            amount: "3000/Month",
            startDate: "13 February 2023",
            status: "Ongoing",
            link: "https://sviet.ac.in"
        },
        {
            projectName: "Innovative Minds Website",
            teamMembers: ["Parveen", "Naveen", "Ronit"],
            amount: "5000",
            startDate: "",
            status: "Completed",
            link: "https://innovativebooks.in/"
        },
        {
            projectName: "ICTA Website & Social Media",
            teamMembers: ["Parveen", "Naveen", "Ronit", "Muntaha"],
            amount: "5000",
            startDate: "December 12",
            status: "February 08",
            link: "Completed icta.in"
        },
        {
            projectName: "AEP Website",
            teamMembers: ["Ronit"],
            amount: "8000",
            startDate: "",
            status: "Completed",
            link: "https://aepstudycircle.com/"
        },
        {
            projectName: "RDCA Website",
            teamMembers: ["Ronit", "Naveen", "Rishikesh Jha"],
            amount: "15000",
            startDate: "April 11, 2023",
            status: "April 18, 2023",
            link: "Completed rdca.com"
        },
        {
            projectName: "RDCA Social Media Handling",
            teamMembers: ["Abhishek Saini"],
            amount: "8000/Month",
            startDate: "April 11, 2023",
            status: "Ongoing",
            link: ""
        },
        {
            projectName: "Rajpura Marathon Website and Social Media Handling",
            teamMembers: ["Mantasha", "Muntaha", "Ronit"],
            amount: "5000",
            startDate: "March 06, 2023",
            status: "March 31, 2023",
            link: "Completed https://ais.ac.in/rajpura-marathon"
        },
        {
            projectName: "Swastik Paint Service Printables",
            teamMembers: ["Abhishek Saini"],
            amount: "10,000",
            startDate: "",
            status: "Completed",
            link: ""
        },
        {
            projectName: "JaiShree Enterpises Social Media",
            teamMembers: ["Abhishek Saini"],
            amount: "5000/Month",
            startDate: "",
            status: "Ongoing",
            link: ""
        },
        {
            projectName: "Tourila Website",
            teamMembers: ["Parveen", "Priyanshi", "Naveen"],
            amount: "15000",
            startDate: "",
            status: "Completed",
            link: "https://toureila.netlify.app/"
        },
        {
            projectName: "Honour's Speak",
            teamMembers: ["Naveen", "Parveen"],
            amount: "10000",
            startDate: "",
            status: "Completed",
            link: "https://hunarspeaks.com/"
        },
        {
            projectName: "Uday Caterers",
            teamMembers: ["Aditya Jadon"],
            amount: "5000",
            startDate: "22 July",
            status: "Oct",
            link: "Ongoing"
        },
        {
            projectName: "Unique Patho Labs",
            teamMembers: ["Arsh Hashmi", "Amber Hashmi"],
            amount: "10,000/Month + 10000 (one time)",
            startDate: "",
            status: "ongoing",
            link: "https://instagram.com/uniquepatholabs?igshid=OGQ5ZDc2ODk2ZA=="
        },
        {
            projectName: "Sawstik Medical",
            teamMembers: ["Amber Hashmi", "Arsh Hashmi"],
            amount: "5000/month",
            startDate: "",
            status: "ongoing",
            link: "https://instagram.com/swastikmedical001?igshid=OGQ5ZDc2ODk2ZA=="
        },
        {
            projectName: "SVIET NAAC Website",
            teamMembers: ["Ronit", "Rishikesh", "Parveen", "Naveen", "Mantasha"],
            amount: "",
            startDate: "",
            status: "Completed",
            link: ""
        },
        {
            projectName: "13SB Cleaning Serives Website",
            teamMembers: ["Ronit", "Krishna", "Vaishnavi"],
            amount: "10,000",
            startDate: "3 Aug",
            status: "17 Aug",
            link: "Completed https://13sbcleaning.com/"
        },
        {
            projectName: "13SB Social Media Handling",
            teamMembers: ["Vaishnavi"],
            amount: "5000/Month",
            startDate: "3 Aug",
            status: "Ongoing",
            link: ""
        },
        {
            projectName: "Metropolitan Fencing Website",
            teamMembers: ["Ronit", "Laxmi", "Aryan"],
            amount: "15000",
            startDate: "",
            status: "Ongoing",
            link: ""
        },
        {
            projectName: "MetaStar Infra",
            teamMembers: ["Sukriti", "Kushagra"],
            amount: "7000/Month",
            startDate: "",
            status: "Ongoing",
            link: ""
        },
        {
            projectName: "Ais Banur Website",
            teamMembers: ["Parveen", "Nishant", "Kumar Sujal", "Amandeep"],
            amount: "",
            startDate: "04 Aug",
            status: "Ongoing",
            link: ""
        },
        {
            projectName: "AG Events and on the bank graphic designing",
            teamMembers: ["Krishna Bansal"],
            amount: "3000/month",
            startDate: "June 27",
            status: "Ongoing",
            link: "https://www.instagram.com/agevents.official/ and https://www.instagram.com/onthebank.official/"
        },
        {
            projectName: "E world",
            teamMembers: ["Arsh Hashmi", "Amber Hashmi"],
            amount: "5000/Month + 5000(one time)",
            startDate: "",
            status: "ongoing",
            link: ""
        },
        {
            projectName: "Garg Pharma",
            teamMembers: ["Parveen", "Aryan", "Vasu"],
            amount: "5000",
            startDate: "",
            status: "Completed",
            link: "https://www.gargpharma.in/"
        },
        {
            projectName: "Saarthak GIMSS School",
            teamMembers: ["Gourav"],
            amount: "16000",
            startDate: "",
            status: "Completed",
            link: ""
        },
        {
            projectName: "Digital Amigos",
            teamMembers: ["Anam Rashid", "Riya", "Vasu"],
            amount: "3,000/ monthly",
            startDate: "August 19",
            status: "ongoing",
            link: ""
        },
        {
            projectName: "Chaloghumetravels (Website)",
            teamMembers: ["Mukul", "Ronit"],
            amount: "7000/-",
            startDate: "November 06",
            status: "OnGoing",
            link: "https://www.chaloghumetravels.com"
        }
    ];



    const filteredProjects = filter === 'All' ? projects : projects.filter(project => project.status === filter);

    return (
        <section>
            <div className="container mx-auto py-8">
                <h1 className="text-3xl font-bold mb-4">Project List</h1>
                <div className="flex justify-center space-x-4">
                    <button onClick={() => setFilter('All')} className={`text-lg font-medium ${filter === 'All' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}>All</button>
                    <button onClick={() => setFilter('Ongoing')} className={`text-lg font-medium ${filter === 'Ongoing' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}>Ongoing</button>
                    <button onClick={() => setFilter('Completed')} className={`text-lg font-medium ${filter === 'Completed' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}>Completed</button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredProjects.map((project, index) => (
                        <div key={index} className="bg-gray-100 p-4 rounded-md shadow-md h-full flex flex-col justify-between">
                            <div className="header">
                                <h2 className="text-xl font-semibold mb-2">{project.projectName}</h2>
                                <p className="mb-2"><strong>Team Members:</strong> {project.teamMembers.join(', ')}</p>
                            </div>
                            <div className="desc">
                                <p className="mb-2"><strong>Amount:</strong> {project.amount}</p>
                                <p className="mb-2"><strong>Start Date:</strong> {project.startDate}</p>
                                <p className="mb-2"><strong>Status : </strong> {project.status}</p>
                            </div>
                            <div className="link">
                                <p className="mb-2"><a href={project.link}>View Project</a></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
