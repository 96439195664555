import React from 'react'
import Hero from '../../components/Service/Servicesub/softwaredevelopment/Hero'
import Description from '../../components/Service/Servicesub/softwaredevelopment/Description'
import Technologies from '../../components/Service/Servicesub/softwaredevelopment/Technologies'
import Number from '../../components/Service/Servicesub/softwaredevelopment/Number'

const Softdev = () => {
    return (
        <div>
            <Hero />
            <Description />
            <Technologies />
            <Number />
        </div>
    )
}

export default Softdev
