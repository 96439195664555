import React from 'react'
import Hero from '../../components/Service/Servicesub/campaignmanagement/Hero'
import Description from '../../components/Service/Servicesub/campaignmanagement/Description'
import Technologies from '../../components/Service/Servicesub/campaignmanagement/Technologies'
import Number from '../../components/Service/Servicesub/campaignmanagement/Number'
const Campaignmanagement = () => {
  return (
    <div>
          <Hero />
            <Description />
            <Technologies />
            <Number />
    </div>
  )
}

export default Campaignmanagement
