import React from "react";
import image from '../../assets/Images/mainhero.avif'
import "../../styling/services.css";
import { SocialIcon } from 'react-social-icons'
import {Link} from 'react-router-dom'

function Hero() {
  return (
    <div className="div">
      <section className="bg-[#00112d]">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className=" ssh mr-auto place-self-center lg:col-span-7">
            <h1 className="  text-transparent font-normal text-3xl md:text-5xl  bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500 ">
              Beyond Code,<br /> Beyond Boundaries
            </h1>
            <p className="max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-xl text-gray-400 pt-5">
              Crafting Exceptional Software Solutions Tailored to Propel Your Business Forward.
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a
                href="https://www.instagram.com/godigitifynexus/"
                className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-centerrounded-lg sm:w-auto focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800"
              >
                <SocialIcon url="https://www.instagram.com/godigitifynexus/" bgColor="#111827" fgColor="white" />
                {/* <img src={imagr} alt="" srcset="" /> */}
                View on Instagram
              </a>
              <a
                href="https://www.linkedin.com/company/godigitifynexus"
                className="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-sm font-medium rounded-lg sm:w-auto focus:outline-none focus:z-10 focus:ring-4  focus:ring-gray-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700"
              >
                <SocialIcon url="https://www.linkedin.com/company/godigitifynexus" bgColor="#1F2937" fgColor="white" />
                View on LinkedIn
              </a>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={image} alt="hero image" style={{ borderRadius: "40px", boxShadow: "10px 10px 5px #1F2937" }} />
          </div>
        </div>
      </section>

    </div>
  );
}

export default Hero;
