import React from 'react'
import Hero from '../../components/Service/Servicesub/logodesign/Hero'
import Description from '../../components/Service/Servicesub/logodesign/Description'
import Technologies from '../../components/Service/Servicesub/logodesign/Technologies'
import Number from '../../components/Service/Servicesub/logodesign/Number'
const Logodesign = () => {
  return (
    <div>
         <Hero />
            <Description />
            <Technologies />
            <Number />
    </div>
  )
}

export default Logodesign
