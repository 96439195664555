import React from 'react'
import CreateBlog from '../../src/components/Blog/CreateBlog'
import { useState } from 'react';
import logo from '../../src/assets/Brand/Godigitify Nexus Actual Logo.png'
import AdminPage from '../Dashboard/AdminPage';

const Admin = ({blogs}) => {
    const [adminId, setAdminId] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Replace this condition with your actual validation logic
        if (adminId === 'admin' && adminPassword === 'password') {
            setLoggedIn(true);
        } else {
            alert('Invalid credentials');
        }
    };

    const handleLogout = () => {
        setLoggedIn(false);
        // Clear adminId and adminPassword if needed
        setAdminId('');
        setAdminPassword('');
    };

    if (loggedIn) {
        return (
            <div className='flex flex-col'>
                <AdminPage log={handleLogout} blogs={blogs} />
                {/* <div>
                    <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded">Logout</button>
                </div> */}
            </div>
        );
    }
    return (
        <div className='flex flex-col items-center h-[90vh]  justify-center bg-purple-800'>
            <div className="w-10/12 md:w-1/4 h-[500px] flex flex-col justify-center bg-white rounded-lg shadow-lg p-5 mx-auto mt-10">
                <img src={logo} alt="" srcset="" width="150px" className='mx-auto my-5' />
                <h2 className="text-2xl font-bold text-center my-5">Admin Login</h2>
                <form onSubmit={handleSubmit} className="max-w-md">
                    <div className="mb-4">
                        <label htmlFor="adminId" className="block font-semibold mb-2">Admin ID:</label>
                        <input type="text" id="adminId" value={adminId} onChange={(e) => setAdminId(e.target.value)} className="border border-gray-300 px-3 py-2 w-full" required />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="adminPassword" className="block font-semibold mb-2">Password:</label>
                        <input type="password" id="adminPassword" value={adminPassword} onChange={(e) => setAdminPassword(e.target.value)} className="border border-gray-300 px-3 py-2 w-full" required />
                    </div>
                    <button type="submit" className="bg-purple-800 text-white px-4 py-2 my-5 rounded hover:bg-purple-900">Login</button>
                </form>
            </div>
        </div>
    )
}

export default Admin