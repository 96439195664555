import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, our unwavering dedication extends to shaping purpose-driven digital experiences through our cutting-edge service – Innovative Enterprise Software Solutions.

        </h2>
        <p className="pt-10">
          <strong>Purpose-Driven Solutions</strong> <br />
          Embark on a tailored digital journey with us, where each enterprise software solution is meticulously crafted to fulfill a distinct purpose. Beyond aesthetics, our goal is to create software that establishes profound connections between your enterprise and its strategic goals.

        </p>
        <p className="pt-4">
          <strong>Innovation at the Core</strong> <br />
          Innovation defines our approach. Our enterprise software solutions integrate cutting-edge technologies, intuitive design, and forward-thinking functionalities to deliver solutions that set your enterprise apart in the digital landscape.

        </p>
        <p className="pt-4">
          <strong>Engagement Excellence</strong> <br />
          Engagement is the cornerstone of our strategy. From seamless interfaces to immersive interactions, we prioritize creating enterprise software that captivates users, fostering meaningful connections with your brand at an enterprise level.

        </p>
      </div>
    </div>
  )
}

export default Description
