import React from 'react';
import { motion } from 'framer-motion';

const TimelineItem = ({ date, title, description }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="relative flex justify-between"
        >
            <div className="flex flex-col items-center w-10 mr-4 md:w-24">
                <div>
                    <div className="flex items-center justify-center w-8 h-8 bg-[#00112d] rounded-full">
                        <div className="w-4 h-4 bg-white rounded-full "></div>
                    </div>
                </div>
                <div className="w-px h-full bg-[#00112d]"></div>
            </div>
            <div>
                <h2 className="inline-block px-4 py-2 mb-4 text-xs font-medium text-gray-100 bg-gradient-to-r from-[#00112d] to-[#00112d] rounded-md">
                    {date}
                </h2>
                <div className="relative flex-1 mb-10 bg-white border-b-4 border-[#00112d] shadow rounded-xl">
                    <div className="relative z-20 p-6">
                        <p className="mb-2 text-xl font-medium text-[#00112d] ">{title}</p>
                        <p className="text-gray-700 ">{description}</p>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

const HorizontalTimeline = () => {
    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="py-24 bg-gray-100 font-poppins"
        >
            <div className="max-w-6xl mx-auto px-4 py-4 lg:py-8 md:px-6">
                <div className="max-w-xl mx-auto text-center">
                    <div className="relative flex flex-col items-center">
                        <motion.h1
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="text-6xl font-medium leading-tight"
                        >
                            Our Remarkable Journey
                        </motion.h1>
                        <div className="flex w-24 mt-1 mb-10 overflow-hidden rounded">

                        </div>
                    </div>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="mb-16 text-base text-center text-gray-500"
                    >
                        We embarked on our journey as a Digital Marketing and Branding (DMB) company in 2022 with a
                        dedicated team of 15 individuals. In just six months, we successfully onboarded 25+ clients,
                        setting a solid foundation for our growth.
                    </motion.p>
                </div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="w-full mx-auto lg:max-w-3xl flex flex-col space-y-12"
                >
                    <TimelineItem
                        date="January 2022"
                        title="Company Inception"
                        description="Founded as a DMB company with a team of 15 individuals, marking the beginning of our journey."
                    />
                    <TimelineItem
                        date="July 2022"
                        title="Client Expansion"
                        description="Secured 25+ clients within the first 6 months, showcasing rapid growth and client trust."
                    />
                    <TimelineItem
                        date="January 2023"
                        title="Team and International Growth"
                        description="Expanded our team to 50+ members and gained international clients from USA, Canada, Zimbabwe."
                    />
                    <TimelineItem
                        date="July 2023"
                        title="Diversification into Software Development"
                        description="Evolved into a full-fledged software development company providing software dev, app dev and e-commerce solutions"
                    />
                </motion.div>
            </div>
        </motion.section>
    );
};

export default HorizontalTimeline;
