import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">

          Effortlessly plan, execute, and analyze campaigns for optimal performance with GoDigitify's intuitive campaign management.
        </h2>
        <p className="pt-10">
        <strong>Customized Campaign Strategy</strong> <br />
        At GoDigitify, we understand that every business is unique, which is why we craft personalized campaign strategies tailored to your specific objectives and target audience. Our team works closely with you to identify your goals, understand your brand identity, and assess your market landscape.
        </p>
        <p className="pt-4">
        <strong>Seamless Campaign Execution</strong> <br />
        With GoDigitify's campaign management services, you can trust that every aspect of your campaign will be handled with precision and efficiency. From content creation to scheduling and deployment, our team ensures that every detail is meticulously planned and executed. 
        </p>
        <p className="pt-4">
        <strong>Data-Driven Optimization</strong> <br />
        In today's fast-paced digital landscape, it's essential to continuously monitor and analyze campaign performance to stay ahead of the competition. That's why GoDigitify provides robust analytics and reporting tools to track key metrics and measure the success of your campaigns in real-time.
        </p>
      </div>
    </div>
  )
}

export default Description
