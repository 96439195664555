import React from 'react'
import img1 from '../../../../assets/Images/Tech/illus.png'
import img2 from '../../../../assets/Images/Tech/coreldraw.png'
import img3 from '../../../../assets/Images/Tech/Sketch.png'
import imm from '../../../../assets/Images/Tech/logov.webp'
const Technologies = () => {
  return (
    <div>
      <section className="min-h-screen grid grid-cols-4 lg:grid-cols-12 gap-14 max-w-screen-xl mx-auto py-10 px-7 md:px-16 pt-20">
        <div className="col-span-4 lg:col-span-5 ">
          <div className="relative h-full">
            <img
              src={imm}
              alt="Logo"
              className="w-full rounded-xl shadow-md md:rounded-2xl md:shadow-lg h-48 md:h-72 lg:h-full object-cover"
            />
            <div className="absolute bottom-0 text-white bg-gradient-to-b from-transparent to-gray-700 rounded-2xl h-auto p-5 md:p-10">
              <span className="mb-2 inline-block text-sm font-light">
                Logo Design
              </span>
              <p className="text-sm leading-relaxed">
                Whether it's sculpting purpose-driven logos, infusing innovation into every design element, or redefining visual engagement on a grand scale, our Innovative Logo Designing services at GoDigitify ensure your brand's logo becomes a timeless symbol, leaving an enduring and meaningful impact on your objectives.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-4 lg:col-span-7 flex flex-col justify-center">
          <span className="inline-block mb-3 uppercase text-sm tracking-wide font-medium text-gray-900">
            Tools and Tecnology{" "}
          </span>
          <h1 className="text-4xl md:text-5xl md:leading-tight font-medium text-gray-900 xl:max-w-full">
            We Used Latest{" "}
            <span className="text-purple-900">Tools And Technology</span> for Logo Designing
          </h1>
          <div className="grid gap-10 lg:max-w-xl pt-10 md:pt-14">
            <div className="sm:flex gap-6 items-center">
              <img
                src={img2}
                alt="Esther Howard"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                  CorelDraw
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                  CorelDRAW is another vector-based design program that enables logo designers to create professional illustrations. It offers a range of tools for drawing, editing, and managing vector graphics, making it a popular choice for logo creation.

                </p>
              </div>
            </div>
            <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
              <img
                src={img1}
                alt="Eleanor Pena"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                  Illustrator
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                  Adobe Illustrator is a vector graphics editor that allows designers to create scalable and high-resolution illustrations. It is widely used in logo design due to its precision, versatility, and ability to create clean, resizable graphics.

                </p>
              </div>
            </div>
            <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
              <img
                src={img3}
                alt="Dianner Russell"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                  Sketch
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                  Sketch is a vector-based design tool primarily used in the field of user interface and web design. While not exclusively for logos, Sketch's simplicity and efficiency make it a popular choice for creating modern and minimalist logo designs.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Technologies
