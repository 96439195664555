import React from 'react'
import img1 from '../../../../assets/Images/Tech/blockchain.png'
import img2 from '../../../../assets/Images/Tech/ar.jpg'
import img3 from '../../../../assets/Images/Tech/pwa.png'
import imm from '../../../../assets/Images/Tech/ecommerce.jpeg'
const Technologies = () => {
  return (
    <div>
      <section className="min-h-screen grid grid-cols-4 lg:grid-cols-12 gap-14 max-w-screen-xl mx-auto py-10 px-7 md:px-16 pt-20">
  <div className="col-span-4 lg:col-span-5 ">
    <div className="relative h-full">
      <img
        src={imm}
        alt="E commerce"
        className="w-full rounded-xl shadow-md md:rounded-2xl md:shadow-lg h-48 md:h-72 lg:h-full object-cover"
      />
      <div className="absolute bottom-0 text-white bg-gradient-to-b from-transparent to-gray-700 rounded-2xl h-auto p-5 md:p-10">
        <span className="mb-2 inline-block text-sm font-light">
        E-commerce Store Development

        </span>
        <p className="text-sm leading-relaxed">
        At GoDigitify, we're dedicated to delivering e-commerce solutions that not only meet but exceed your expectations. Partner with us to unlock the full potential of your online business and achieve unparalleled success in the digital marketplace.
        </p>
      </div>
    </div>
  </div>
  <div className="col-span-4 lg:col-span-7 flex flex-col justify-center">
    <span className="inline-block mb-3 uppercase text-sm tracking-wide font-medium text-gray-900">
      Tools and Tecnology{" "}
    </span>
    <h1 className="text-4xl md:text-5xl md:leading-tight font-medium text-gray-900 xl:max-w-full">
      We Used Latest{" "}
      <span className="text-purple-900">Tools And Technology</span> for E-commerce Store Development

    </h1>
    <div className="grid gap-10 lg:max-w-xl pt-10 md:pt-14">
      <div className="sm:flex gap-6 items-center">
        <img
          src={img2}
          alt="Esther Howard"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
          Augmented Reality (AR) Shopping
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          By overlaying digital product models onto real-world scenes captured through a device's camera, AR enhances the online shopping experience, improves product understanding, and reduces purchase hesitation.

          </p>
        </div>
      </div>
      <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
        <img
          src={img1}
          alt="Eleanor Pena"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
            
Blockchain
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          Blockchain technology enhances supply chain transparency and traceability by creating an immutable ledger of transactions and product data.
          </p>
        </div>
      </div>
      <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
        <img
          src={img3}
          alt="Dianner Russell"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
          Progressive Web Apps (PWAs)
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          PWAs combine the best features of websites and mobile apps to deliver fast, reliable, and engaging user experiences. Leveraging modern web technologies such as service workers and web app manifests.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
      
    </div>
  )
}

export default Technologies
