import React from "react";
import { Link } from "react-router-dom";
const Calltoaction = () => {
    const handleClick = () => {
        // Scroll smoothly to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return (
        <div className="bg-white py-10">
            <div className="mx-auto max-h-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
                <div className="relative pb-5 isolate overflow-hidden bg-[#00112d] px-6 pt-8 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-16 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                        aria-hidden="true"
                    >
                        <circle
                            cx={512}
                            cy={512}
                            r={512}
                            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                            fillOpacity="0.7"
                        />
                        <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#00112d" />
                                <stop offset={1} stopColor="#7775D6" />
                            </radialGradient>
                        </defs>
                    </svg>

                    <div className="m-auto text-center lg:mx-0 lg:my-0 lg:flex-auto lg:py-16 lg:text-left">
                        <h2 className="text-3xl w-full font-medium tracking-tight text-white sm:text-4xl">
                            Think you've got what it takes to be a part of the GoDigitify Nexus family?
                        </h2>
                        <p className="mt-6 max-w-md text-lg leading-8 text-gray-300 pt-2">
                            Explore exciting opportunities and embark on a fulfilling journey with us.
                        </p>

                        <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-end">
                            <div className=" just md:ml-3 invisible lg:visible bg-[#ffffff] mt-3 h-[1px] lg:w-[400px]"></div>
                            <Link onClick={handleClick}
                                to="/contact"
                                className="px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:scale-110 hover:-translate-y-1 transition-transform duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 ring-1 ring-white rounded-2xl focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Get started
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Calltoaction;