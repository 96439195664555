import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
        Ready to fortify your digital presence? Step into the realm of online success with GoDigitify.
        </h2>
        <p className="pt-10">
        <strong>Tailored Reputation Management Strategies</strong> <br />
         Whether you're a burgeoning startup or an established enterprise, we craft bespoke solutions to safeguard and enhance your online reputation.
        </p>
        <p className="pt-4">
         <strong>Strategic Content Curation</strong> <br />
        Bid farewell to reputation woes! Our team curates compelling content that bolsters your brand image, resonating with your audience and fostering trust.
        </p>
        <p className="pt-4">
         <strong>Mobile-Optimized Visibility</strong> <br />
        With mobile usage on the rise, we ensure your online reputation shines on all devices. From smartphones to tablets, we've got you covered!
        </p>
      </div>
    </div>
  )
}

export default Description
