import React from 'react'
import Hero from '../../components/Service/Servicesub/Enterprisesoftwaredev/Hero'
import Description from '../../components/Service/Servicesub/Enterprisesoftwaredev/Description'
import Technologies from '../../components/Service/Servicesub/Enterprisesoftwaredev/Technologies'
import Number from '../../components/Service/Servicesub/Enterprisesoftwaredev/Number'
const EnterpriseSolutions = () => {
  return (
    <div>
      <Hero />
            <Description />
            <Technologies />
            <Number />
    </div>
  )
}


export default EnterpriseSolutions
