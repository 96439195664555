import React from 'react'
import Hero from '../../components/Service/Servicesub/onlinereputationmanagement/Hero'
import Description from '../../components/Service/Servicesub/onlinereputationmanagement/Description'
import Technologies from '../../components/Service/Servicesub/onlinereputationmanagement/Technologies'
import Number from '../../components/Service/Servicesub/onlinereputationmanagement/Number'
const Onlinereputation = () => {
  return (
    <div>
             <Hero />
            <Description />
            <Technologies />
            <Number />
      
    </div>
  )
}

export default Onlinereputation
