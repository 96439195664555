import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
        At GoDigitify, we've mastered the art of crafting purpose-driven digital experiences through our premier service – Innovative Web Development. 



        </h2>
        <p className="pt-10">
         <strong> Purpose-Driven Development</strong><br></br>
          Embark on a digital journey with us where each website is meticulously crafted with a distinct purpose. We understand that your online presence should go beyond aesthetics, aiming to connect with your audience on a deeper level
        </p>
        <p className="pt-4">
          <strong>Innovation at Your Fingertips</strong><br></br>
          GoDigitify thrives on innovation. Our web development service incorporates cutting-edge technologies, intuitive design, and forward-thinking functionalities.

        </p>
        <p className="pt-4">
          <strong>Engagement Redefined</strong><br></br>
          Engagement is not just a buzzword; it's at the core of what we do. From compelling content strategies to interactive design elements, we prioritize creating websites that captivate your audience.
        </p>
      </div>
    </div>
  )
}

export default Description
