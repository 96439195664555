import React, { useState } from 'react';
import CreateBlog from '../components/Blog/CreateBlog';
import Projects from '../Views/Projects';
import AllBlogs from '../components/Blog/AllBlogs';
import AddProject from './AddProject';
import ContactResponse from '../components/FormResponses/ContactResponse'
import AdminBlogs from './AdminBlogs';
import Enquiries from './Enqiries';
const AdminPage = ({ log, blogs }) => {
    const [selectedTab, setSelectedTab] = useState('createBlog'); // Default selected tab

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    return (
        <div>
            <div className='h-[50vh] flex flex-col justify-center bg-purple-800 text-white text-center'>
                <p className="text-4xl">
                    Hello Admin
                </p>
            </div>
            <div className="flex flex-col lg:flex-row">
                {/* Sidebar */}
                <div className="w-full lg:w-1/6 bg-gray-800 text-white p-4">
                    <h2 className="text-2xl font-bold mb-4">Admin Dashboard</h2>
                    <ul>
                        <li
                            className={`py-3 border-b-2 border-white   cursor-pointer mb-2 ${selectedTab === 'createBlog' ? 'text-blue-500' : ''
                                }`}
                            onClick={() => handleTabChange('createBlog')}
                        >
                            Create Blog
                        </li>
                        <li
                            className={`py-3 border-b-2 border-white   cursor-pointer mb-2 ${selectedTab === 'projects' ? 'text-blue-500' : ''
                                }`}
                            onClick={() => handleTabChange('projects')}
                        >
                            Projects
                        </li>
                        <li
                            className={`py-3 border-b-2 border-white   cursor-pointer mb-2 ${selectedTab === 'allBlogs' ? 'text-blue-500' : ''
                                }`}
                            onClick={() => handleTabChange('allBlogs')}
                        >
                            All Blogs
                        </li>
                        <li
                            className={`py-3 border-b-2 border-white   cursor-pointer mb-2 ${selectedTab === 'addProject' ? 'text-blue-500' : ''
                                }`}
                            onClick={() => handleTabChange('addProject')}
                        >
                            Add Project
                        </li>
                        <li
                            className={`py-3 border-b-2 border-white   cursor-pointer mb-2 ${selectedTab === 'formResponse' ? 'text-blue-500' : ''
                                }`}
                            onClick={() => handleTabChange('formResponse')}
                        >
                            Form Responses
                        </li>
                        <li>
                            <button className='bg-red-500 text-white p-2 w-full mt-4' onClick={log}> Log Out</button>
                        </li>
                    </ul>
                </div>

                {/* Content */}
                <div className="w-full lg:w-5/6 p-4">
                    {/* Render the component based on the selected tab */}
                    {selectedTab === 'createBlog' && <CreateBlog />}
                    {selectedTab === 'projects' && <Projects />}
                    {selectedTab === 'allBlogs' && <AdminBlogs/>}
                    {selectedTab === 'addProject' && <AddProject />}
                    {selectedTab === 'formResponse' && <Enquiries />}
                
                </div>
            </div>
        </div>
    );
};

export default AdminPage;
