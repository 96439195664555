import React from 'react'
import ContactStart from '../layout/ContactStart'
import Footer from '../layout/Footer'
import ContactForm from '../layout/ContactForm'
import ContactBase from '../layout/ContactBase'
// import Navbar from '../layout/Navbar'

function Contact() {
  return (
    <div>
      <ContactStart/>
      <ContactForm/>
      <ContactBase/>
      </div>
  )
}

export default Contact