import React from 'react'
import backdrop from '../../assets/Backdrop/awards-backdrop-desktop.jpg'
import backdrop2 from '../../assets/Backdrop/awards-backdrop-mobile.jpg'
import img1 from '../../assets/awards/1.jpg'
import img2 from '../../assets/awards/2.jpg'
import img from '../../assets/Backdrop/card-bg1.jpg'
const RecogsAwards = () => {
    return (
        <div>
            <div className='hidden md:block awards-backdrop py-8 h-[200vh] md:flex items-end text-white' style={{ backgroundImage: `url(${backdrop})`, backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <div className="w-10/12 m-auto grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                    <div className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                        <div className="bg-white h-[40vh] rounded-md" style={{ backgroundImage: `url(${img})`, backgroundSize: "cover" }}>

                        </div>
                        <div className="absolute inset-0"></div>
                        <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                            <h1 className="font-dmserif text-medium font-medium text-purple-950 pb-10 text-2xl mb-5">Indian Startup Awards</h1>
                            <p className="mb-3 text-lg italic text-black opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                Godigitify Nexus proudly received the prestigious Startup India Award 2022</p>
                            {/* <button className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">
                            See More
                        </button> */}
                        </div>
                    </div>
                    <div className="  group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                        <div className="bg-white h-[40vh] rounded-md" style={{ backgroundImage: `url(${img})`, backgroundSize: "cover", backgroundPosition: "center center" }}>

                        </div>
                        <div className="absolute inset-0"></div>
                        <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                            <h1 className="font-dmserif text-medium font-medium text-purple-950 pb-10 text-2xl mb-5">Indian CSR Awards</h1>
                            <p className="mb-3 text-lg italic text-black opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                Godigitify Nexus proudly received the prestigious Indian CSR Award 2022</p>
                            {/* <button className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">
                            See More
                        </button> */}
                        </div>
                    </div>



                </div>
            </div>
            <div className='md:hidden flex awards-backdrop py-8 h-[200vh] items-end text-white' style={{ backgroundImage: `url(${backdrop2})`, backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center" }}>
                <div className="w-10/12 m-auto grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                    <div className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                        <div className="bg-white h-[40vh] rounded-md" style={{ backgroundImage: `url(${img})`, backgroundSize: "cover" }}>

                        </div>
                        <div className="absolute inset-0"></div>
                        <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                            <h1 className="font-dmserif text-medium font-medium text-purple-950 pb-10 text-2xl">Indian Startup Awards</h1>
                            <p className="mb-3 text-lg italic text-black opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                Godigitify Nexus proudly received the prestigious Startup India Award 2022</p>
                            {/* <button className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">
                        See More
                    </button> */}
                        </div>
                    </div>
                    <div className="  group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                        <div className="bg-white h-[40vh] rounded-md" style={{ backgroundImage: `url(${img})`, backgroundSize: "cover", backgroundPosition: "center center" }}>

                        </div>
                        <div className="absolute inset-0"></div>
                        <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                            <h1 className="font-dmserif text-medium font-medium text-purple-950 pb-10 text-2xl">Indian CSR Awards</h1>
                            <p className="mb-3 text-lg italic text-black opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                Godigitify Nexus proudly received the prestigious Indian CSR Award 2022</p>
                            {/* <button className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">
                        See More
                    </button> */}
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default RecogsAwards