import React from 'react'
import img1 from '../../../../assets/Images/Tech/illus.png'
import img2 from '../../../../assets/Images/Tech/photoshop.png'
import img3 from '../../../../assets/Images/Tech/xd.png'
import imm from '../../../../assets/Images/Tech/graphicv.webp'
const Technologies = () => {
  return (
    <div>
      <section className="min-h-screen grid grid-cols-4 lg:grid-cols-12 gap-14 max-w-screen-xl mx-auto py-10 px-7 md:px-16 pt-20">
  <div className="col-span-4 lg:col-span-5 ">
    <div className="relative h-full">
      <img
        src={imm}
        alt="Graphic"
        className="w-full rounded-xl shadow-md md:rounded-2xl md:shadow-lg h-48 md:h-72 lg:h-full object-cover"
      />
      <div className="absolute bottom-0 text-white bg-gradient-to-b from-transparent to-gray-700 rounded-2xl h-auto p-5 md:p-10">
        <span className="mb-2 inline-block text-sm font-light">
          Graphic Designing
        </span>
        <p className="text-sm leading-relaxed">
        Whether it's crafting purpose-driven visuals, infusing innovation into every design, or redefining visual engagement on a grand scale, our Innovative Graphic Designing services at GoDigitify ensure your brand's visual presence leaves an enduring and meaningful impact on your objectives
        </p>
      </div>
    </div>
  </div>
  <div className="col-span-4 lg:col-span-7 flex flex-col justify-center">
    <span className="inline-block mb-3 uppercase text-sm tracking-wide font-medium text-gray-900">
      Tools and Tecnology{" "}
    </span>
    <h1 className="text-4xl md:text-5xl md:leading-tight font-medium text-gray-900 xl:max-w-full">
      We Used Latest{" "}
      <span className="text-purple-900">Tools And Technology</span> for Graphic Designing
    </h1>
    <div className="grid gap-10 lg:max-w-xl pt-10 md:pt-14">
      <div className="sm:flex gap-6 items-center">
        <img
          src={img2}
          alt="Esther Howard"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
            Photoshop
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          Embark on a visual journey where each design element is meticulously curated using Photoshop. We blend strategic thinking with visual aesthetics, ensuring that your graphics align seamlessly with your brand's goals.

          </p>
        </div>
      </div>
      <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
        <img
          src={img1}
          alt="Eleanor Pena"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
            Illustrator
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          Innovation is at the core of our approach. Utilizing Illustrator, we create designs with precision and scalability. From logos to intricate illustrations, our designs stand out with clarity and adaptability across various mediums.

          </p>
        </div>
      </div>
      <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
        <img
          src={img3}
          alt="Dianner Russell"
          className="w-24 h-24 object-cover rounded-2xl flex-none"
        />
        <div>
          <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
            XD
          </span>
          <p className="text-sm leading-relaxed font-semibold text-gray-900">
          Engagement is key to our design strategy. XD enables us to go beyond static visuals, creating immersive prototypes that bring your designs to life. Users experience your brand in a dynamic, interactive environment.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
      
    </div>
  )
}

export default Technologies
