import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, we infuse creativity into every pixel through our cutting-edge service – Innovative Graphic Designs. Our proficiency in Adobe Creative Cloud, including Photoshop, Illustrator, and XD, allows us to craft purposeful visuals that elevate your brand
        </h2>
        <p className="pt-10">
          <strong>Comprehensive Design Expertise</strong> <br />
          Leverage our comprehensive expertise across Photoshop, Illustrator, and XD for a holistic approach to your graphic design needs. From brand identity to marketing collateral, our designs are tailored to resonate with your target audience.

        </p>
        <p className="pt-4">
          <strong>Innovative Branding</strong> <br />
          Elevate your brand visuals with our innovative approach to Photoshop, Illustrator, and XD. Whether it's designing a captivating logo or crafting interactive prototypes, we use these tools to establish a distinctive and memorable brand identity.

        </p>
        <p className="pt-4">
          <strong>Operational Efficiency with Adobe Suite</strong> <br />
          Optimize your design processes with the efficiency of Adobe Creative Cloud. Our proficiency in Photoshop, Illustrator, and XD streamlines collaboration, ensuring the swift, creative, and reliable delivery of custom graphic designs.


        </p>
      </div>
    </div>
  )
}

export default Description
