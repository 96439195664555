
import React from 'react';
import about from '../../assets/Images/about2.gif'
import headerImage from '../../assets/careers-v.png'
const Header = () => {
    return (
        <div className=''>


            <section className="py-24 bg-[#00112D] sm:pb-4 lg:pb-24 xl:pb-24">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 w-10/12 py-10 xl:pb-24">
                    <div className="relative">
                        <section className="py-12 bg-[#00112D] flex flex-col justify-center">
                            <div className=" mr-auto">
                                <div className="relative">
                                    <div className="">
                                        <p className="text-sm font-normal tracking-widest text-gray-300 uppercase" style={{ fontFamily: "Montserrat" }}>
                                            Welcome To The World of Godigitify Nexus 
                                        </p>
                                        <h1 className="mt-6 text-4xl font-normal text-white sm:mt-10 sm:text-5xl lg:text-6xl xl:text-6xl">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                                                Elevating Your Brand's <br />
                                            </span>{' '}
                                            Brilliance
                                        </h1>
                                        <p className="max-w-lg mt-4 text-l font-normal text-gray-400 sm:mt-8">
                                        Passionate expertise driving your business to unprecedented success. Elevate your brand with innovative strategies that stand out and propel growth. </p>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className=" md:absolute md:mt-0 lg:top-0 md:right-0 flex flex-col justify-center">
                            <img
                                className="h-120 max-w-xs mx-auto lg:max-w-lg xl:max-w-xl my-auto"
                                src={headerImage} width={"100%"}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Header;

