import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/career-first-vector.png'
import axios from 'axios';
const LandingPageModal = () => {

    const handleClick = () => {
        // Scroll smoothly to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const [formData, setFormData] = useState({
        name: '',
        interest: 'Interest in',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post('https://godigitify-nexus.onrender.com/contact/new', formData);
            console.log(response);


            if (response.status === 201) {
                console.log('Form submitted successfully!');
                alert("Form Submitted Successfully")
            }
            else {
                console.log('Form submitted unsuccessfully!');
            }

        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };
    return (
        <>


            <div className="bg-white pb-10">
                <div className="mx-auto max-h-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
                    <div className="relative pb-5 isolate overflow-hidden bg-[#00112d] px-6 pt-8 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-16 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                        <svg
                            viewBox="0 0 1024 1024"
                            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                            aria-hidden="true"
                        >
                            <circle
                                cx={512}
                                cy={512}
                                r={512}
                                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                                fillOpacity="0.7"
                            />
                            <defs>
                                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                    <stop stopColor="#00112d" />
                                    <stop offset={1} stopColor="#7775D6" />
                                </radialGradient>
                            </defs>
                        </svg>

                        <div className="m-auto text-center lg:mx-0 lg:my-0 lg:flex-auto lg:py-16 lg:text-left">
                            <div className='flex flex-wrap'>
                                <div className='w-full lg:w-2/3'>
                                    <h2 className="text-3xl w-full font-medium tracking-tight text-white sm:text-4xl">
                                        Achieve More with Us                       </h2>
                                    <p className="mt-6 max-w-md text-lg leading-8 text-gray-300 pt-2 sm:mx-auto lg:mx-0">
                                        We can help you achieve your goals by providing guidance, resources, and support tailored to your needs. Whether it's through mentorship, skill development, or networking opportunities, we're here to assist you in reaching your career aspirations.
                                    </p>
                                </div>
                                <div className="w-full md:w-1/3 -mt-28 self-start lg:block hidden">
                                    <img className='object-cover object-right-top' src={img} alt='' />
                                </div>
                            </div>
                            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-end">
                                <div className=" just md:ml-3 invisible lg:visible bg-[#ffffff] mt-3 h-[1px] lg:w-[400px]"></div>
                                <div className='flex  '>
                                    <Link to="/careers" onClick={handleClick}>
                                        <button
                                            className="block py-2 text-white bg-transparent border-2 border-white rounded-lg text-md px-5 text-center"
                                            type="button"
                                        >

                                            Apply for job
                                        </button>
                                    </Link>
                                    <button
                                        onClick={toggleModal}
                                        className="block text-[#00112d] bg-white ml-6 font-semibold rounded-lg text-md px-5 text-center"
                                        type="button"
                                    >
                                        Work with us
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <div
                    id="default-modal"
                    className="fixed  top-0 right-0 left-0 z-50 flex justify-center items-center w-full inset-0 overflow-y-auto overflow-x-hidden"
                >
                    <div className="relative  w-full max-w-2xl">
                        <div className="relative bg-white px-10 rounded-lg shadow ">
                            <div className="flex items-center justify-between p-5 rounded-t ">

                                <button
                                    onClick={toggleModal}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="default-modal"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        ></path>
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>



                            <section class=" contact-bg text-gray-600 body-font relative pb-10   ">
                                <div class="container   mx-auto flex ">
                                    <form onSubmit={handleSubmit} >
                                        <div class=" md:text-start text-center flex flex-col md:ml-auto w-full  ">
                                            <div class="flex flex-col  w-full bg-white ">
                                                <div className='flex'>
                                                    <h2 class="text-lg text-[#4e286e] tracking-widest font-medium ml-5 lg:ml-0 title-font mb-1">got a burning query</h2> <div className='lg:ml-3 invisible lg:visible bg-[#4e286e] mt-3 h-[2px] lg:w-[200px]'></div>
                                                </div>
                                                <h1 class="sm:text-5xl mt-2 text-2xl leading-9  title-font text-gray-900">let’s discuss your project</h1>

                                            </div>
                                            <div class="flex pt-9 items-center w-full">
                                                <div class="relative w-full lg:w-4/5">
                                                    <select class="rounded border appearance-none w-full border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10">
                                                        <option>Interest in</option>
                                                        <option>Digital brand management</option>
                                                        <option>Digital brand setup</option>
                                                        <option>Website development</option>
                                                        <option>Search engine optimization</option>
                                                        <option>Brand video films</option>
                                                    </select>
                                                    <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                                            <path d="M6 9l6 6 6-6"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className=" w-full lg:w-4/5 pt-5 flex flex-row justify-between gap-x-3 lg:gap-x-8 ">
                                                <div class="  w-1/2 rounded-lg  flex flex-col     md:mt-0">

                                                    <div class="relative w-full">
                                                        <input type="text" id="full-name"
                                                            name="name"
                                                            placeholder="Your Name"
                                                            value={formData.name}
                                                            onChange={handleChange} class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                                    </div>
                                                    <div class="relative w-full pt-5">
                                                        <input type="email" id="email" placeholder='Your Email' name="email" value={formData.email}
                                                            onChange={handleChange} class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                                    </div>

                                                </div>
                                                <div class="  w-1/2 rounded-lg  flex flex-col    md:mt-0">

                                                    <textarea id="message" name="message" placeholder='Write your requirements here....' value={formData.message}
                                                        onChange={handleChange} class="w-full bg-white h-full rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  text-base outline-none text-gray-700  px-3  leading-6 transition-colors duration-200 ease-in-out"></textarea>

                                                </div>
                                            </div>
                                            <div class="flex md:flex-row flex-col  w-full md:justify-start justify-center items-center pt-5">
                                                <div class="relative mr-4 lg:w-full xl:w-1/2 ">
                                                    <p class="md:text-base text-xs  text-gray-500 font-medium title-font mb-4 md:mb-1">DBM is your all-in-one solution! choose DBM</p>
                                                </div>
                                                <button type='submit' class=" lg:w-fit w-full  text-white bg-[#4e286e] px-6 border-0 py-2 focus:outline-none hover:bg-[#311944] rounded text-lg">Request A qoute </button>
                                            </div>


                                        </div>
                                    </form>

                                </div>

                            </section>





                        </div>
                    </div>
                </div>
            )}
            {/* </div>
            </div> */}
        </>
    );
};

export default LandingPageModal;
