import React from 'react'
import CareersImgCollage from './CareersImgCollage'
import CareerShaping from './CareerShaping'
import CareerSomethingBiggerSection from './CareerSomethingBiggerSection'
import img from '../../../assets/career-first-vector.png'
import { Link } from 'react-router-dom'

const CareersHome = () => {

    return (
        <>

            <section className="py-12 h-[80vh] md:h-[90vh]  bg-gradient-to-r from-[#00112D] to-black sm:pb-4 lg:pb-2 xl:pb-10" >
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl pt-24">
                    <div className="relative flex flex-wrap">
                        <div className="md:w-3/5">
                            <p className="text-sm font-normal tracking-widest text-gray-300 uppercase" style={{ fontFamily: "Montserrat" }}>
                                Godigitify Nexus in Careers
                            </p>
                            <h1 className="mt-6 text-4xl font-normal text-white sm:mt-10 sm:text-5xl lg:text-6xl xl:text-5xl" >
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">

                                    Contemplating
                                </span>{' '}
                                membership with Godigitify
                            </h1>
                            <p className="max-w-lg mt-4 text-xl font-normal text-gray-400 sm:mt-8">

                            Fuel your career with passionate knowledge, propelling you to new levels. Enhance your professional brand with inventive strategies that set you apart and foster personal growth  </p>



                        </div>
                        <div className="md:w-2/5 md:self-end hidden md:block">
                            <img alt='' className='object-contain object-center h-screen ' src={img} />
                        </div>


                    </div>
                </div>
            </section>
            <div className="flex flex-wrap w-full py-12 px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl   hidden">
                <div className='  sm:w-1/3 flex px-8 sm:border-r-2 border-[#00112D]  py-3'>
                    <div class="relative w-full">
                        <select class="rounded border appearance-none w-full border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10">
                            <option>Expertise in</option>
                            <option>digital brand management</option>
                            <option>digital brand setup</option>
                            <option>website development</option>
                            <option>search engine optimization</option>
                            <option>brand video films</option>
                        </select>
                        <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className='  sm:w-1/3 sm:flex px-8 sm:border-r-2 border-[#00112D]  py-3'>
                    <div class="relative w-full">
                        <select class="rounded border appearance-none w-full border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10">
                            <option>Country</option>

                            <option>India </option>
                            <option>Cannada </option>
                            <option>United States of America </option>
                            <option>Others </option>
                        </select>
                        <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                            </svg>
                        </span>
                    </div>
                </div>
                {/* <div className=' sm:w-1/3 flex px-8 sm:border-r-2 border-[#00112D] py-3'>
                    <div class="relative w-full">
                        <select class="rounded border appearance-none w-full border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10">
                            <option>Country</option>
                            
                            <option>India </option>
                            <option>Cannada </option>
                            <option>U.S. </option>
                            <option>Others </option>

                        </select>
                        <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                            </svg>
                        </span>
                    </div>
                </div> */}
                <div className=' sm:w-1/3 flex px-8 justify-center align-middle'>
                    <div class="relative w-full  py-3 ">
                        <Link to="/contact">
                            <button
                                className="block py-2   bg-gradient-to-r from-[#00112D] to-black border-2 text-white rounded-lg text-md px-5 text-center"
                                type="button"
                            >

                                Discover your future Now
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <CareersImgCollage />
            <CareerShaping />
            <CareerSomethingBiggerSection />
        </>
    )
}

export default CareersHome