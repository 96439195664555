import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Enquiries = () => {
    const [enquiries, setEnquiries] = useState([]);

    // Function to fetch all enquiries from the backend
    const fetchEnquiries = async () => {
        try {
            const response = await axios.get('https://godigitify-nexus.onrender.com/enquiry/enquiries');
            console.log('Fetched enquiries:', response.data);
            setEnquiries(response.data.data.enquiries);
        } catch (error) {
            console.error('Error fetching enquiries:', error);
            // Handle error when fetching enquiries
        }
    };

    // Function to delete an enquiry by ID
    const deleteEnquiry = async (id) => {
        try {
            await axios.delete(`https://godigitify-nexus.onrender.com/enquiry/enquiries/${id}`);
            // Remove the deleted enquiry from the state
            setEnquiries(enquiries.filter(enquiry => enquiry._id !== id));
        } catch (error) {
            console.error('Error deleting enquiry:', error);
            // Handle error when deleting enquiry
        }
    };

    useEffect(() => {
        // Fetch enquiries when the component mounts
        fetchEnquiries();
    }, []); // Run this effect only once, when the component mounts

    return (
        <div className="container mx-auto">
            <h2 className="text-2xl font-bold mb-4">Enquiries</h2>
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Email</th>
                        <th className="px-4 py-2">Phone</th>
                        <th className="px-4 py-2">Company</th>
                        <th className="px-4 py-2">Message</th>
                        <th className="px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Add a conditional check for enquiries */}
                    {Array.isArray(enquiries) && enquiries.map(enquiry => (
                        <tr key={enquiry._id}>
                            <td className="border px-4 py-2">{enquiry.name}</td>
                            <td className="border px-4 py-2">{enquiry.email}</td>
                            <td className="border px-4 py-2">{enquiry.phone}</td>
                            <td className="border px-4 py-2">{enquiry.company}</td>
                            <td className="border px-4 py-2">{enquiry.message}</td>
                            <td className="border px-4 py-2">
                                <button
                                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => deleteEnquiry(enquiry._id)}
                                >
                                    Delete
                                </button>
                                {/* Add edit functionality here */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Enquiries;
