import React from 'react';
import Icon from '../../assets/Icons/transformative.png';
import Icon2 from '../../assets/Icons/4.png';
import Icon3 from '../../assets/Icons/1.png';
import Icon4 from '../../assets/Icons/3.png';
import Icon5 from '../../assets/Icons/5.png';
import Icon6 from '../../assets/Icons/2.png';
import backdrop from '../../assets/Icons/backdrop.png';

const serviceData = [
    {
        icon: Icon,
        title: 'Transformative Solutions',
        description:
            'Our services are designed to ignite transformation, offering innovative solutions that reshape and elevate your business.',

    },
    {
        icon: Icon2,
        title: 'Tailored Excellence',
        description:
            'From customized strategies to personalized support, our services are crafted with precision to meet the unique needs of your business.',

    },
    {
        icon: Icon3,
        title: 'End-to-End Support',
        description:
            'Experience comprehensive assistance with our end-to-end services, covering everything from concept to execution, ensuring a seamless journey to success.',

    },
    {
        icon: Icon4,
        title: 'Strategic Partnerships',
        description:
            'We go beyond services; we build lasting partnerships. Collaborate with us to unlock new possibilities and achieve mutual success.',

    },
    {
        icon: Icon5,
        title: 'Responsive Solutions',
        description:
            'Count on us for agile and responsive services that adapt to the dynamic needs of your business, ensuring you stay ahead in a rapidly changing landscape.',

    },
    {
        icon: Icon6,
        title: 'Customer-Centric Approach',
        description:
            'Your satisfaction is our priority. Our customer-centric services are designed to exceed your expectations and leave a lasting positive impact.',

    },
];

const OurServices = () => {
    return (
        <div className=" py-10 w-10/12 mx-auto mt-4 relative">
            <div
                className="backdrop"
                style={{
                    backgroundImage: `url(${backdrop})`,
                    backgroundSize: '80%',
                    backgroundPosition: 'center',
                    opacity: 0.15,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: -1,
                }}
            ></div>
            <div id="features" className="mx-auto relative z-10">
                <h2 className="text-left font-display text-3xl font-medium tracking-tight text-black md:text-4xl" style={{ fontFamily: "Montserrat" }}>
                    Our Collaborative Environment
                </h2>
                <p className="text-left text-slate-600 text-base font-semibold leading-7 text-primary-500 mt-8" style={{ fontFamily: "Montserrat" }}>
                    Immerse yourself in the ethos of GoDigitify Nexus, where teamwork, innovation, and shared success define our
                    workplace dynamics.
                </p>
                <ul className="mt-8 grid gap-6 lg:grid-cols-3 md:grid-cols-2">
                    {serviceData.map((service, index) => (
                        <li key={index} className="rounded-xl px-6 py-8">
                            <a className="group">
                                <img src={service.icon} alt="" className="mx-auto h-40 w-40" />
                                <h3 className="my-3 font-display text-xl font-semibold group-hover:text-primary-500" style={{ fontFamily: "Montserrat" }}>
                                    {service.title}
                                </h3>
                                <p className="mt-3.5 text-sm leading-6 text-secondary-500 text-justify" style={{ fontFamily: "Montserrat" }}>
                                    {service.description}
                                </p>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default OurServices;
