import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, we're dedicated to sculpting purpose-driven digital experiences through our cutting-edge service – Innovative Cloud Solutions.

        </h2>
        <p className="pt-10">
          <strong>Purpose-Driven Solutions</strong><br />
          Embark on a transformative journey with us, where each cloud solution is intricately designed to serve a distinct purpose. Beyond aesthetics, our goal is to create cloud solutions that establish profound connections between your business and its overarching objectives.

        </p>
        <p className="pt-4">
          <strong>Innovation at the Core</strong> <br />
          Innovation is the heartbeat of our approach. Our cloud solutions integrate cutting-edge technologies, intuitive design, and forward-thinking functionalities to deliver solutions that propel your business ahead in the digital realm.

        </p>
        <p className="pt-4">
          <strong>Engagement Excellence</strong> <br />
          Engagement remains pivotal to our strategy. From seamless interfaces to immersive interactions, we prioritize creating cloud solutions that captivate users, fostering meaningful connections with your brand in the cloud ecosystem.

        </p>
      </div>
    </div>
  )
}

export default Description
