import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, our commitment extends to crafting purpose-driven digital experiences through our cutting-edge service – Innovative Custom Software Development.

        </h2>
        <p className="pt-10">
          <strong>Purpose-Driven Solutions:</strong><br />
          Embark on a tailored digital journey with us, where each custom software solution is meticulously crafted to serve a distinct purpose. Beyond aesthetics, our goal is to create software that forges deeper connections between your business and its objectives.

        </p>
        <p className="pt-4">
          <strong>Innovation at the Core:</strong><br />
          Innovation defines our approach. Our custom software development service integrates cutting-edge technologies, intuitive design, and forward-thinking functionalities to deliver solutions that set your business apart.

        </p>
        <p className="pt-4">
          <strong>Engagement Excellence:</strong><br/>
          Engagement is the cornerstone of our strategy. From seamless interfaces to immersive interactions, we prioritize creating custom software that captivates users, fostering meaningful connections with your brand.

        </p>
      </div>
    </div>
  )
}

export default Description
