import React from 'react'
import Hero from '../../components/Service/Servicesub/socialmediamanagement/Hero'
import Description from '../../components/Service/Servicesub/socialmediamanagement/Description'
import Technologies from '../../components/Service/Servicesub/socialmediamanagement/Technologies'
import Number from '../../components/Service/Servicesub/socialmediamanagement/Number'
const SocialMedia = () => {
  return (
    <div>
      <Hero />
      <Description />
      <Technologies />
      <Number />
    </div>
  )
}
export default SocialMedia
