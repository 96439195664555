import React from "react";
import image from '../../../../assets/Images/ECommerceHeader.webp'
import "../../../../styling/services.css";

function Hero() {
  return (
    <div
      className="text-center h-96 relative"
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="bg-gradient-to-r from-purple-900 to-purple-500 absolute inset-0 opacity-75 flex items-center justify-center">
        <h2 className="text-white text-4xl px-10 text-center md:text-left">E-Commerce Solutions</h2>
      </div>
    </div>
  );
}

export default Hero;
