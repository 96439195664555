import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

// Team images
import img1 from '../../assets/Teams/ronit.jpg'
import img2 from '../../assets/Teams/parveen.jpg'
import img3 from '../../assets/Teams/Mantasha.jpg'
import img4 from '../../assets/Teams/anam.jpg'
import img5 from '../../assets/Teams/arsh.jpg'
import img6 from '../../assets/Teams/taha1.jpg'
import img12 from '../../assets/Teams/amberr.jpg'
import img7 from '../../assets/Teams/aditya.jpg'
import img8 from '../../assets/Teams/tripti.jpg'
import img9 from '../../assets/Teams/saini.jpg'
import img10 from '../../assets/Teams/rishi.jpg'
import img11 from '../../assets/Teams/aadi.jpg'
import img13 from '../../assets/Teams/priyanshi.jpg'
import img14 from '../../assets/Teams/kushagra.jpg'
import img15 from '../../assets/Teams/sukriti.jpg'
import img16 from '../../assets/Teams/naveen.jpeg'


const TeamMemberCard = ({ name, role, imageUrl, description, linkedinUrl }) => {
    return (
        <a className="flex flex-col mb-8 overflow-hidden rounded lg:flex-row lg:pl-28 md:mr-4 md:ml-4">
            <div className="w-full overflow-hidden lg:w-2/4 h-80">
                <img className="object-cover w-full h-full transition-all hover:scale-110 rounded-2xl" src={imageUrl} alt={name} />
            </div>
            <div className="relative flex self-center h-80 flex-1 p-8 ml-0 lg:w-80 bg-white border rounded-lg shadow lg:items-center lg:-ml-12">
                <div>
                    <h2 className="mb-2 text-xl font-medium ">{name}</h2>
                    <p className="mb-4 text-sm">{role}</p>
                    <p className="mb-4 text-sm line-clamp-5">{description}</p>
                    <p className="mb-4 text-sm font-medium">Know More</p>
                    <div className="flex items-center">
                        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className="text-[#1C4E81] hover:text-blue-500 text-xl">
                            <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
                        </a>
                    </div>
                </div>
            </div>
        </a>
    );
};


const Team = () => {
    const sliderRef = useRef(null);

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="w-full box-border container p-4 md:p-8 m-0 mx-auto">
            <header className="h-20 flex items-center justify-center gap-4 md:gap-8">
                <div className="title flex-1">
                    <h3 className="text-5xl font-medium text-gray-800 text-center">Team <span className='text-[#47226b] '>Nexus</span> </h3>
                </div>
            </header>

            <Slider ref={sliderRef} {...sliderSettings}>
                <TeamMemberCard
                    name="Ronit Jaiprakash"
                    role="Full Stack Developer"
                    imageUrl={img1}
                    description="
                    Hi, I'm Ronit, your full-stack developer at GoDigitify Nexus. Specializing in front-end design and powerful back-end solutions, I'm dedicated to transforming ideas into elegant, functional software for exceptional digital experiences."
                    linkedinUrl="https://www.linkedin.com/in/ronit-jai-prakash-58082122a"
                />
                <TeamMemberCard
                    name="Parveen Jaiswal"
                    role="Full Stack Developer"
                    imageUrl={img2}
                    description="I'm the tech artisan behind the screen, a Full Stack Developer sculpting immersive digital experiences. From pixel-perfect interfaces to robust server logic, I blend creativity with code for a seamless user journey."
                    linkedinUrl="https://www.linkedin.com/in/parveen-jaiswal/"
                />
                <TeamMemberCard
                    name="Naveen Jaiswal"
                    role="Full Stack Developer"
                    imageUrl={img16}
                    description="I'm the tech artisan behind the screen, a Full Stack Developer sculpting immersive digital experiences. From pixel-perfect interfaces to robust server logic, I blend creativity with code for a seamless user journey."
                    linkedinUrl="https://www.linkedin.com/in/naveen-jaiswal/"
                />
                <TeamMemberCard
                    name="Priyanshi Sharma"
                    role="Full Stack Developer"
                    imageUrl={img13}
                    description="I'm a digital alchemist, merging design magic with code wizardry. A Full Stack Developer, I sculpt intuitive interfaces and fortify systems, blending creativity with technical acumen to weave seamless digital experiences."
                    linkedinUrl="https://www.linkedin.com/in/priyanshi-sharma-aa6391239/"
                />
                <TeamMemberCard
                    name="Mantasha Tabassum"
                    role="Full Stack Developer"
                    imageUrl={img3}
                    description="
                    As Event & Branding Lead at GDSC-SVIET, I shape impactful events and contribute to brand strategy. Simultaneously, as a Full-stack Developer at Godigitify, I create robust web solutions with MCD Level-1 certification. Also, a Graphic Designer and Trainer at Techlearns Academy."
                    linkedinUrl="https://www.linkedin.com/in/mantasha-tabassum-0322ab250/"
                />
                <TeamMemberCard
                    name="Anam Rashid"
                    role="Full Stack Developer"
                    imageUrl={img4}
                    description="
                    I'm a versatile content creator and web developer skilled in crafting compelling presentations. Proficient in Python for data analysis, I seamlessly blend creativity and technical expertise in content creation, presentation design, and web development."
                    linkedinUrl="https://www.linkedin.com/in/anam-rashid-b3527a255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                />

                <TeamMemberCard
                    name="Muntaha Tabassum"
                    role="Full Stack Developer"
                    imageUrl={img6}
                    description="
                    I'm a Full-stack Developer at Godigitify with a MCD Level-1 certification, dedicated to modern coding practices. Also serving as a Graphic Designer, I blend technical expertise with creativity. As a Trainer at Techlearns Academy, I contribute to the growth of aspiring individuals in tech. 
                    "
                    linkedinUrl="https://www.linkedin.com/in/muntaha-tabassum-456858250?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                />
                <TeamMemberCard
                    name="Amber Hashmi"
                    role="Full Stack Developer"
                    imageUrl={img12}
                    description="
                    As a versatile professional, I excel as a full-stack developer, graphic designer, and digital marketer, holding MuleSoft certification. My diverse skill set uniquely positions me at the intersection of technology, design, and marketing in the digital realm."
                    linkedinUrl="https://www.linkedin.com/in/amber-hashmi-940689253/"
                />
                <TeamMemberCard
                    name="Arsh Hashmi"
                    role="Full Stack Developer"
                    imageUrl={img5}
                    description=" As a Developer and Graphic Designer, I sculpt user experiences with precision in code and elevate them with visually compelling designs, crafting a seamless fusion of technology and art."
                    linkedinUrl="https://www.linkedin.com/in/123arshhashmi?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                />
                <TeamMemberCard
                    name="Aditya Jadon"
                    role="Full Stack Developer"
                    imageUrl={img7}
                    description="Seasoned Full Stack Developer experience in front-end (HTML, CSS, JavaScript, React) and back-end (Node.js, Python, Java). Proven success in leading projects, optimizing e-commerce platforms, and implementing scalable solutions."
                    linkedinUrl="https://www.linkedin.com/in/aditya-jadon-92692a230?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                />
                <TeamMemberCard
                    name="Tripti Mandal"
                    role="Full Stack Developer"
                    imageUrl={img8}
                    description="As a Full Stack Developer, I traverse the digital landscape with a dual-purpose compass. Designing elegant interfaces and architecting resilient systems, I navigate the intricate terrain where user experience meets technological prowess."
                    linkedinUrl="https://www.linkedin.com/in/tripti-mandal-3a247b233/"
                />
                <TeamMemberCard
                    name="Abhishek Saini"
                    role="Full Stack Developer"
                    imageUrl={img9}
                    description="
                    I excel in HTML, CSS, and JavaScript, utilizing frameworks like React and Angular. With expertise in Tailwind CSS for responsive styling, I'm skilled in backend technologies like Node.js, Django (Python), and databases like MongoDB and MySQL."
                    linkedinUrl="https://www.linkedin.com/in/abhishek-saini-77171721b/"
                />
                <TeamMemberCard
                    name="Rishikesh Jha"
                    role="Full Stack Developer"
                    imageUrl={img10}
                    description="
                    I design seamless digital experiences, blending front-end elegance with back-end robustness. A Full Stack Developer, I navigate code intricacies to sculpt user-friendly interfaces and architect resilient systems, shaping the digital landscape with innovation."
                    linkedinUrl="https://www.linkedin.com/in/rishikesh-jha-840612226"
                />
                <TeamMemberCard
                    name="Aditya Raj"
                    role="Full Stack Developer"
                    imageUrl={img11}
                    description="Bridging the gap between pixels and databases, I am a Full Stack Developer. With a palette of coding languages, I paint user interfaces and construct resilient backends, crafting digital landscapes that stand the test of time."
                    linkedinUrl="https://www.linkedin.com/in/aditya-raj-589053259"
                />

                <TeamMemberCard
                    name="Kushagra Tripathi"
                    role="Developer & Graphic Designer"
                    imageUrl={img14}
                    description="Bridging the gap between code and aesthetics, I am a Developer and Graphic Designer. Crafting clean, efficient code and visually striking designs, I create digital realms where form meets function seamlessly."
                    linkedinUrl="https://www.linkedin.com/in/kushagra-tripathi-63306b22a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                />
                <TeamMemberCard
                    name="Sukriti Kumari    "
                    role="Full Stack Developer Graphic Designer"
                    imageUrl={img15}
                    description="With a dual hat of Full Stack Developer and Graphic Designer, I sculpt code and visuals in symphony. From elegant interfaces to captivating graphics, I transform concepts into immersive digital experiences."
                    linkedinUrl="https://www.linkedin.com/in/sukriti-kumari-563a70252?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                />
            </Slider>


            <div className="flex justify-center mt-4">
                <FontAwesomeIcon icon={faChevronLeft} className="text-2xl cursor-pointer mr-2" onClick={() => sliderRef.current.slickPrev()} />
                <FontAwesomeIcon icon={faChevronRight} className="text-2xl cursor-pointer ml-2" onClick={() => sliderRef.current.slickNext()} />
            </div>
        </div>
    );
};

export default Team;
