import React from 'react'
import Hero from '../../components/Service/Servicesub/webdevelopment/Hero'
import Description from '../../components/Service/Servicesub/webdevelopment/Description'
import Technologies from '../../components/Service/Servicesub/webdevelopment/Technologies'
import Number from '../../components/Service/Servicesub/webdevelopment/Number'

const Web = () => {
  return (
    <div>
      <Hero/>
      <Description/>
      <Technologies/>
      <Number/>
    </div>
  )
}

export default Web
