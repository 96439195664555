import React, { useState } from 'react';

const AddProject = () => {
    const [projectData, setProjectData] = useState({
        projectName: '',
        teamMembers: [''], // Initial state with one empty member field
        amount: '',
        startDate: '',
        status: '',
        link: ''
    });

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedMembers = [...projectData.teamMembers];
        updatedMembers[index] = value;
        setProjectData({ ...projectData, teamMembers: updatedMembers });
    };

    const handleAddMember = () => {
        setProjectData({ ...projectData, teamMembers: [...projectData.teamMembers, ''] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Make an API call to your backend to add the project
        // You can use fetch or Axios for making API calls
        fetch('https://godigitify-nexus.onrender.com/api/projects', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(projectData)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Project added successfully:', data);
            alert("Project Added Successfully")
            // Handle success response from the backend
            // Optionally, you can redirect the user or show a success message
        })
        .catch(error => {
            console.error('Error adding project:', error);
            // Handle error response from the backend
            // Optionally, you can show an error message to the user
        });

        // Clear form fields after submission
        setProjectData({
            projectName: '',
            teamMembers: [''],
            amount: '',
            startDate: '',
            status: '',
            link: ''
        });
    };

    return (
        <div className=" mx-auto mt-8 p-4 bg-white rounded">
            <h2 className="text-xl font-bold mb-4">Add New Project</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Project Name:</label>
                    <input type="text" name="projectName" value={projectData.projectName} onChange={(e) => setProjectData({ ...projectData, projectName: e.target.value })} required className="border border-gray-300 rounded px-3 py-2 w-full" />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Team Members:</label>
                    {projectData.teamMembers.map((member, index) => (
                        <input key={index} type="text" value={member} onChange={(e) => handleChange(e, index)} required className="border border-gray-300 rounded px-3 py-2 w-full mb-2" />
                    ))}
                    <button type="button" onClick={handleAddMember} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Add Member</button>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Amount:</label>
                    <input type="text" name="amount" value={projectData.amount} onChange={(e) => setProjectData({ ...projectData, amount: e.target.value })} required className="border border-gray-300 rounded px-3 py-2 w-full" />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Start Date:</label>
                    <input type="text" name="startDate" value={projectData.startDate} onChange={(e) => setProjectData({ ...projectData, startDate: e.target.value })} required className="border border-gray-300 rounded px-3 py-2 w-full" />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Status:</label>
                    <input type="text" name="status" value={projectData.status} onChange={(e) => setProjectData({ ...projectData, status: e.target.value })} required className="border border-gray-300 rounded px-3 py-2 w-full" />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Link:</label>
                    <input type="text" name="link" value={projectData.link} onChange={(e) => setProjectData({ ...projectData, link: e.target.value })} required className="border border-gray-300 rounded px-3 py-2 w-full" />
                </div>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Add Project</button>
            </form>
        </div>
    );
};

export default AddProject;
