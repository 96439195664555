import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, we sculpt brand identities with precision through our cutting-edge service – Innovative Logo Designs.

        </h2>
        <p className="pt-10">
          <strong>Distinctive Logo Craftsmanship</strong> <br />
          Leverage our expertise in logo design for a distinct and memorable brand identity. We meticulously craft logos that embody your brand's values, ensuring they resonate with your target audience and leave a lasting impression.

        </p>
        <p className="pt-4">
          <strong>Innovative Branding Approach</strong> <br />
          Elevate your brand's visual representation with our innovative approach to logo design. Using Adobe Illustrator, we bring creativity to the forefront, ensuring that your logo becomes a unique symbol that sets your brand apart.

        </p>
        <p className="pt-4">
          <strong>Scalable Designs with Clarity</strong> <br />
          Innovation remains at the core of our logo design philosophy. Utilizing Illustrator, we create logos with precision and scalability. Whether it's for digital platforms or print media, our designs maintain clarity and adaptability across various mediums.

        </p>
      </div>
    </div>
  )
}

export default Description
