import React from "react";
import "../../styling/services.css";
function Search() {
  return (
    <div>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid cols-1 w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8  dark:border-gray-700 bottt">
        <div className="">
          <h5 className="text-4xl text-violet pb-20 lg:py-20 md:py-10  text-left lg:ml-80 sm:ml:40">
            Stay up to date with insights from <br></br> Godigitify Nexus
          </h5>
          <div className="vl" />
        </div>
        <div className="text-left px-30 lg:mt-20 md:mt-10 sm:-mt-20">
          <div>
            <form>
              {/* <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Subscribe
              </label> */}
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-2/3 p-4 ps-10 text-sm text-gray-900 border-2 border-black rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email"
                  required=""
                />
                {/* <button
                  type="submit"
                  className="text-white absolute  bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:bg-violet-600 dark:hover:bg-black dark:focus:ring-blue-800 searchbuttons "
                >
                  Subscribe
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
