import React from 'react'
import Hero from '../../components/Service/Servicesub/Bs/Hero'
import Description from '../../components/Service/Servicesub/Bs/Description'
import Technologies from '../../components/Service/Servicesub/Bs/Technologies'
import Number from '../../components/Service/Servicesub/Bs/Number'

const Bs = () => {
    return (
        <div>
            <Hero />
            <Description />
            <Technologies/>
            <Number/>
        </div>
    )
}

export default Bs
