import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  // Fetch all blogs from the backend API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://godigitify-nexus.onrender.com/blogs');
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  // Function to handle deletion of a blog
  const handleDelete = async (blogId) => {
    try {
      // Make DELETE request to backend to delete the blog
      await axios.delete(`https://godigitify-nexus.onrender.com/blogs/${blogId}`);
      // Remove the deleted blog from the state
      setBlogs(blogs.filter(blog => blog._id !== blogId));
      console.log('Blog deleted successfully');
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-bold my-4">All Blogs</h2>
      <ul>
        {blogs.map(blog => (
          <li key={blog._id} className="my-4 py-4">
            <h3 className="text-xl font-bold">{blog.title}</h3>
            {/* <p className="my-2">{blog.content}</p> */}
            <p className="text-gray-600">Author: {blog.authorName}</p>
            {/* Delete button for each blog */}
            <button
              onClick={() => handleDelete(blog._id)}
              className="mt-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none"
            >
              Delete
            </button>
            <hr className="my-4" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminBlogs;
