import React from "react";
import Hero from "../components/Service/Hero";
import Description from "../components/Service/Description";
import Section from "../components/Service/Section";
import Section1 from "../components/Service/Section1";
import Search from "../components/Service/Search";
import Calltoaction from '../components/About/Calltoaction'
// import Section2 from "../components/Service/Section2";
import Section3 from "../components/Service/Section3";
const Services = () => {
  return (
    <div>
      <Hero />
      <Section3/>
      <Description />
      <Section></Section>
      <Calltoaction/>

    </div>
  );
};

export default Services;
