import React from 'react'
import img1 from '../Careers/../../../assets/careerImg-1.jpg'
import img2 from '../Careers/../../../assets/careerImg-2.jpg'
import img3 from '../Careers/../../../assets/careerImg-3.jpg'
import img4 from '../Careers/../../../assets/careerImg-4.jpg'
import img5 from '../Careers/../../../assets/careerImg-5.jpg'
import img6 from '../Careers/../../../assets/careerImg-6.jpg'
import img7 from '../Careers/../../../assets/careerImg-7.jpg'
import bg from '../Careers/../../../assets/career-bg.png';



const CareersImgCollage = () => {
  return (
    <>
      <div className="flex py-16  h-full ">
      <div class="w-1/4 sm:flex hidden  flex-col    ">
      <div class="w-full   hidden sm:block  p-2 h-80 ">
      <img className="flex-shrink-0   w-full  h-full object-cover object-center "
          src={img1} alt=''/>
      </div>
      <div class="w-1/2   hidden sm:block  p-2 h-44  self-end ">
      <img className="flex-shrink-0   w-full  h-full object-cover object-center "
          src={img2} alt=''/>
        
</div>
    </div>

    <div class="w-1/4  hidden sm:block self-center    p-2  ">
        <div className="w-full h-64 ">
        <img className="flex-shrink-0   w-full  h-full object-cover object-center "
          src={img3} alt=''/>
        </div>
    </div>

    <div class="w-1/4 sm:flex flex-wrap hidden    p-2">
    <div class="w-full  hidden sm:block  h-60   p-2">
    <img className="flex-shrink-0   w-full  h-full object-cover object-center "
          src={img4} alt=''/>
    </div>
    <div class="w-1/2   hidden sm:block h-64   p-2">
    <img className="flex-shrink-0   w-full  h-full object-cover object-center "
          src={img5} alt=''/>
    </div>

    <div class="w-1/2  h-36 self-start hidden sm:block   p-2">
    <img className="flex-shrink-0   w-full  h-full object-cover object-center "
          src={img6} alt=''/>
    </div>

    </div>

    <div class="sm:w-1/4 w-full  self-center h-96    p-2">
    <img className="flex-shrink-0   w-full  h-full object-cover object-center "
          src={img7} alt=''/>
    </div>
      </div>
      <div className="flex bg-gradient-to-r from-[#00112D] to-black text-white justify-center align-middle pt-20 pb-24">
        <div className='w-4/5 md:w-2/3 text-center'>
        {/* <h1 className="   text-2xl py-12 " >
              Ek pyara sa Culture...
        </h1> */}
        <h1 className=" text-3xl font-normal md:pb-24 pb-12 text-white sm:mt-10 sm:text-3xl lg:text-4xl xl:text-6xl" >
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">

                        Unveiling Godigitify Nexus: Where Culture Blooms with Excellence
                        </span>{' '}
                     
                    </h1>
      

        <p>
Here at Godigitify Nexus, we don't just work, we build a family. It's not just about being team members; it's about being precious companions on this digital journey together. Godigitify Nexus is a place where we value a lovely culture, where each individual feels at home.
The family at Godigitify Nexus is bound by our values, respect, and collaboration. Here, we understand each other, support one another, and fulfill each other's needs. It's a family where everyone's contribution is significant, and everyone's voice is heard.

</p>
     <img className='opacity-5 z-0 -mt-48 ' src={bg} alt=''/>
        <p className='py-5 hidden md:block -mt-44'>At Godigitify Nexus, we embrace a lovely culture, which can also be called our friends and family. The environment, the people, and the work – everything is a lovely experience that touches the heart of every employee.
</p>

      </div>
      </div>
    </>
  )
}

export default CareersImgCollage
