import React from "react";

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, we go beyond aesthetics to shape powerful brand
          narratives through our comprehensive Branding Strategy services. Our
          expertise in crafting unique and impactful strategies ensures that
          your brand not only stands out but also resonates deeply with your
          target audience.
        </h2>
        <p className="pt-10">
          <strong>Strategic Brand Identity Development:</strong>
          <br />
          Leverage our seasoned professionals to craft a distinctive brand
          identity that goes beyond logos. We delve into the core values of your
          business, creating a visual and narrative identity that reflects your
          brand's essence and establishes a strong connection with your
          audience.
        </p>
        <p className="pt-4">
          <strong>Memorable Brand Storytelling:</strong>
          <br />
          Elevate your brand's narrative with our innovative approach to
          storytelling. We believe that every brand has a story, and our
          strategy revolves around narrating it effectively. We utilize various
          mediums to ensure your brand story is not just heard but remembered.
        </p>
        <p className="pt-4">
          <strong>Consistent Visual Language:</strong>
          <br />
          Maintain a cohesive and recognizable presence across all touchpoints
          with our consistent visual language strategy. From logo design to
          marketing collateral, we ensure that every element speaks the same
          visual language, reinforcing your brand identity in the minds of your
          audience.
        </p>
      </div>
    </div>
  );
};

export default Description;
