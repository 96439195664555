import React from 'react'
import Header from '../components/About/Header'
// import Services2 from '../components/About/services'
import Vision from '../components/About/Vision'
import Calltoaction from '../components/About/Calltoaction'
import Founder from '../components/About/Founder'
import Timeline from '../components/About/Timeline'
import Team from '../components/About/Team'
import OurServices from '../components/About/OurServices'

import Awards from '../components/About/Awards'
import Testimonials from '../components/About/Testimonials'
import AwardsNew from '../components/About/AwardsNew'
import TestiOld from '../components/About/TestiOld'
const About = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <Header />
      <OurServices />
      <Vision />
      {/* <Services2/> */}

      <Founder />
     
      <Team />
      <Timeline/>
      {/* <Testimonials /> */}
      <TestiOld/>
      {/* <AwardsNew /> */}
      <Calltoaction />
    </div>
  )
}

export default About