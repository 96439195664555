import React, { useState, useEffect } from 'react';
import './herostyle.css';
import lp1 from '../../assets/Images/lp1.avif'
import lp2 from '../../assets/Images/lp2.avif'
import lp3 from '../../assets/Images/lp3.avif'
import lp4 from '../../assets/Images/lp4.jpeg'
import { Link } from 'react-router-dom';
const FeaturedStorySlider = () => {
  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => goToNextSlide(), 6000);

    return () => clearInterval(intervalId);
  }, [currentSlideIndex]);

  const stories = [
    {
      title: "Experience the Godigitify Nexus Advantage",
      howWeHelped: "Embark on a journey of digital transformation with us and discover a world where technology meets imagination.",
      link: "/about-us",
      imageSrc:lp1   },
    {
      title: "Navigating the Digital Realm with Unmatched Creativity  ",
      howWeHelped: "Our mission is to guide you through the labyrinth of digital possibilities, unlocking the true potential of your business in the digital realm.",
      link: "/about-us",
      imageSrc: lp2 },
    {
      title: "Embrace the Magic of Innovation",
      howWeHelped: "Our services encompass crafting captivating web and mobile experiences, igniting brands with social media sorcery, engineering custom software solutions, and navigating the digital realm with SEO mastery.",
      link: "/about-us",
      imageSrc: lp3},
    {
      title: "Discover the fruitful power of innovation with Godigitify Nexus",
      howWeHelped: "Elevate your brand and reap extraordinary possibilities in the digital realm.",
      link: "/about-us",
      imageSrc: lp4},
    // Add more stories as needed
  ];

  const updateStory = (index) => {
    const storyListElements = document.querySelectorAll('.story-list');
    storyListElements.forEach((element, i) => {
      if (i === index) {
        element.classList.add('active-slide');
      } else {
        element.classList.remove('active-slide');
      }
    });
  };

  const updateStoryPagination = (index) => {
    const paginationBlocks = document.querySelectorAll('.slide-pagination-block');
    paginationBlocks.forEach((block, i) => {
      if (i === index) {
        block.classList.add('active-slide-pagination-block');
      } else {
        block.classList.remove('active-slide-pagination-block');
      }
    });
  };

  const goToSlide = (index) => {
    setCurrentSlideIndex(index);
    updateStory(index);
    updateStoryPagination(index);
  };

  const goToPrevSlide = () => {
    const index = (currentSlideIndex - 1 + stories.length) % stories.length;
    goToSlide(index);
  };

  const goToNextSlide = () => {
    const index = (currentSlideIndex + 1) % stories.length;
    goToSlide(index);
  };

  return (
    <div className="featured-story-wrapper" style={{fontFamily:"Montserrat"}}>
      <div className="featured-story-list">
        <section className="slider-outer">
          <div className="row-fluid">
            <div className="span1">
              <div className="carousel__progress hide-mobile">
                <span className="carousel__progress-background">
                  <span className="carousel__progress-bar"></span>
                </span>
              </div>
            </div>
            <div className="span11 content-section">
              <div id="StorySliderID" className="storyslider regular slider">
                <div className="story-slider-wrapper" data-cursor="cursor" data-cursor-type="drag">
                  {stories.map((story, index) => (
                    <div key={index} className={`story-list ${index === currentSlideIndex ? 'active-slide' : ''}`} data-slide-index={index}>
                      <div className="left-section span5">
                        <div className="drag-overlay hide-mobile"></div>
                        <div className="client-logo hide-mobile"></div>
                        <div className="client-logo hide-desktop"></div>
                        <div className="story-content" style={{fontFamily:'Montserrat'}}>
                          <h4 className='mm'>{story.title}</h4>
                          <div className="description brand-04-neg-20 small-text">

                            <h2 className='mn'><span style={{ color: '#05051e', fontFamily:'Montserrat' }}>{story.howWeHelped}</span></h2>
                          </div>
                          <Link onClick={handleClick} to={story.link} className="page-btn page-btn03" style={{textDecoration:"none", fontFamily:'Montserrat' }}>Read the story</Link>
                        </div>
                      </div>
                      <div className="right-section span7">
                        <div className="right-section-content">
                          <div className="right-section--image">
                            <img src={story.imageSrc} loading="lazy" className="right-section--imageSrc img" alt={story.title} />
                            <div className="mobile-overlay"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* Pagination section start */}
                  <ul className="story--slider-pagination-section">
                    <li className="slider-numbers">
                      <span className="xs-pagination-number">{currentSlideIndex + 1}</span>
                      <span className="total-number">{stories.length}</span>
                    </li>
                    <li className="slide-arrow slider-pagination-left" onClick={goToPrevSlide} id="storyprevSlideControl">
                      <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34 2C28.5 8.00001 6 16 6 16C6 16 29 25.5 34 30" stroke="#13294B" strokeWidth="4" strokeLinecap="round"></path>
                      </svg>
                    </li>
                    <li className="slide-arrow slider-pagination-right" onClick={goToNextSlide} id="storynextSlideControl">
                      <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2C7.5 8.00001 30 16 30 16C30 16 7 25.5 2 30" stroke="#13294B" strokeWidth="4" strokeLinecap="round"></path>
                      </svg>
                    </li>
                  </ul>
                  {/* Pagination section end */}

                  {/* Additional Pagination section for visual representation */}
                  <ul className="story--slider-pagination-section" style={{ overflow: 'hidden' }}>
                    {stories.map((_, index) => (
                      <li key={index} className={`slide-pagination-block ${currentSlideIndex === index ? 'active-slide-pagination-block' : ''}`} data-pagination-index={index} onClick={() => goToSlide(index)}>
                        <div className="slide-pagination-number">{index + 1}</div>
                        <div className="slide-pagination-text"></div>
                      </li>
                    ))}
                  </ul>
                  {/* Additional Pagination section end */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FeaturedStorySlider;
