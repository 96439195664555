import React from "react";
import img1 from "../../../../assets/Images/Tech/ar.jpg";
import img2 from "../../../../assets/Images/Tech/blockchain.png";
import img3 from "../../../../assets/Images/Tech/data.jpg";
import imm from "../../../../assets/Images/Tech/brandv.jpeg";
const Technologies = () => {
  return (
    <div>
      <section className="min-h-screen grid grid-cols-4 lg:grid-cols-12 gap-14 max-w-screen-xl mx-auto py-10 px-7 md:px-16 pt-20">
        <div className="col-span-4 lg:col-span-5 ">
          <div className="relative h-full">
            <img
              src={imm}
              alt="branding"
              className="w-full rounded-xl shadow-md md:rounded-2xl md:shadow-lg h-48 md:h-72 lg:h-full object-cover"
            />
            <div className="absolute bottom-0 text-white bg-gradient-to-b from-transparent to-gray-700 rounded-2xl h-auto p-5 md:p-10">
              <span className="mb-2 inline-block text-sm font-light">
                Branding Strategy
              </span>
              <p className="text-sm leading-relaxed">
                “ Whether it's developing a strategic brand identity, crafting
                memorable brand stories, maintaining a consistent visual
                language, engaging across multiple platforms, positioning your
                brand strategically, or providing comprehensive brand
                guidelines, our Branding Strategy services at GoDigitify
                guarantee that your brand not only communicates effectively but
                leaves an enduring and meaningful impact on your audience.”
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-4 lg:col-span-7 flex flex-col justify-center">
          <span className="inline-block mb-3 uppercase text-sm tracking-wide font-medium text-gray-900">
            Tools and Tecnology{" "}
          </span>
          <h1 className="text-4xl md:text-5xl md:leading-tight font-medium text-gray-900 xl:max-w-full">
            We Used Latest{" "}
            <span className="text-purple-900">Tools And Technology</span> for
            Branding Strategy {" "}
          </h1>
          <div className="grid gap-10 lg:max-w-xl pt-10 md:pt-14">
            <div className="sm:flex gap-6 items-center">
              <img
                src={img2}
                alt="Esther Howard"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                  BLockchain
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                Blockchain technology is utilized to enhance transparency and trust in branding. It can be applied to supply chain management, certifying the authenticity of products and ensuring ethical practices
                </p>
              </div>
            </div>
            <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
              <img
                src={img1}
                alt="Eleanor Pena"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                Augmented Reality (AR)
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                AR technologies allow brands to create immersive experiences, both online and offline. Through AR applications, consumers can engage with brands in new and interactive ways, enhancing product visualization and providing virtual try-ons.
                </p>
              </div>
            </div>
            <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
              <img
                src={img3}
                alt="Dianner Russell"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                Data Analytics
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                Advanced data analytics and predictive modeling help brands make informed decisions by analyzing large datasets. By understanding customer behaviors and market trends, brands can tailor their strategies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Technologies;
