import React, { useState, useEffect } from 'react';
import LandingPageStoriesCaraousel from './LandingPageStoriesCaraousel';
import LandingPageModal from './LandingPageModal';
import first_vector from '../../assets/mission-vission-vector.png'
import second_vector from '../../assets/vector-2.png'

const LandingPageStories = () => {


  return (
    <div>


      <div className=" relative h-fit text-center lg:text-start pt-5 px-5 md:py-32  flex w-full m-auto dark-color-bg items-center">
        <div className='text-white w-full md:w-4/5  m-auto h-full pt-5m lg:pt-20 pb-12 '>
          <div className="regular-content ">
            <h2 className='text-xl  lg:text-2xl'>Godigitify Nexus- </h2>
            <h2 className='text-2xl md:text-3xl lg:text-4xl'>Where Technology Meets Imagination</h2>

          </div>

          <div className={`lg:sticky top-20 lg:pt-6 `}>
            <h1 className='text-xl md:text-2xl lg:text-3xl'> Step into a world <br /> where digital dreams become reality</h1>
          </div>
          <div className=' md:w-2/3 h-40 z-0 hidden md:block '>        <img className=' object-cover object-left-top opacity-5 md:opacity-20' src={first_vector} alt='' /></div>


          <div className="md:flex justify-end  items-end  ">

            <div className=" w-full py-4 lg:w-2/5">
              <div class="lg:flex-col  p-8 justify-center bg-black bg-opacity-20 rounded md:rounded-full  ">
                <div class="pt-4 flex justify-center ">
                  <div class="h-full  bg-opacity-75  lg:w-4/5 pt-8 lg:pt-16 pb-20 rounded-full overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium  mb-3">Mission:</h1>
                    <div className='flex justify-center'>
                      <p class="leading-relaxed  mb-3 w-4/5 ">We strive to be the artisans of digital transformation, sculpting bespoke solutions that transcend the ordinary and propel businesses into the extraordinary realms of success.</p>
                    </div>


                  </div>
                </div>

                <div className="flex   justify-center">
                  <div class=" h-[2px] bg-white   w-4/5   rounded-full ">
                  </div>
                </div>
                <div class="py-4 flex justify-center ">
                  <div class="h-full lg:w-4/5 pt-8 lg:pt-16 pb-20 rounded-full overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium  mb-3">Vision:</h1>
                    <div className='flex justify-center'>
                      <p class="leading-relaxed text- mb-3 w-4/5 ">Through our visionary approach, we aim to inspire and guide our clients on a transformative journey, where their digital dreams are realized, and their potential is unleashed to its fullest extent.
                      </p>
                    </div>


                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>


      </div>
      {/* <div className="relative h-fit  w-full m-auto pt-8 px-5 lg:pt-24 main-color-bg items-center">
        <div className="lg:flex lg:w-4/5 m-auto  ">
          <div className='text-white lg:w-1/2 md:h-[800px] m-auto box-border	 pt-5 lg:pt-20 pb-8 '>
            <div className={`sticky top-20  `}>
              <h1 className='text-3xl lg:text-4xl '> We Set New Standards for <br />  Excellence</h1>
            </div>
            <div className='  h-60 pr-12 '>        <img className=' object-cover object-center opacity-60' src={second_vector} alt='' /></div>

          </div>
          <div className='text-white lg:w-1/2  m-auto h-full  pb-8 '>
            <LandingPageStoriesCaraousel />
          </div>

        </div>
      </div> */}


    </div>
  );
};

export default LandingPageStories;