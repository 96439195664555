import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios'


import sviet from '../../assets/Clients/svietlogooo-1.webp'
import alliance from '../../assets/Clients/ais.png'
import sb from '../../assets/Clients/sb.png'
import mp from '../../assets/Clients/main-logo.ed4fc28e4013344174d1.png'
import sp from '../../assets/Clients/healing.png'
import aep from '../../assets/Clients/AEP Logo.png'
import ip from '../../assets/Clients/Vanky Logo-04 copy.png'
const grayscaleStyle = {
  filter: 'grayscale(100%)',
};

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://godigitify-nexus.onrender.com/enquiry/enquiries', formData);
      console.log(response.data);
      alert("Enquiry Submitted Successfully")
      // Optionally, you can show a success message or redirect the user to a thank you page
    } catch (error) {
      console.error(error.response.data);
      // Optionally, you can show an error message to the user
    }
  };
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col justify-center text-black">
            <h2 className="text-4xl md:text-5xl font-medium text-purple-800 mb-8">Get in Touch</h2>
            <p className="text-lg">Your success with us begins with a simple form. Whether you have questions, ideas or goals, we're here to listen and respond.</p>
            <p className="text-lg mt-4">Feel free to reach out with any inquiries at <a href="mailto:contact@godigitify.com" style={{ textDecoration: "none", color: "darkviolet" }} className="underline text-purple-800 font-medium"><span className='text-purple-800'>
              contact@godigitify.com</span></a>. We're here to assist you.</p>
            <div>
              <p className="text-lg font-bold mt-8 pt-4">TRUSTED BY <span className='text-purple-800'>GLOBAL COMPANIES</span></p>
              <div className="w-3/4 grid grid-cols-3 gap-4 mt-4">
                <img src={sviet} alt="Logo 1" style={grayscaleStyle} className="w-[100px] h-auto my-auto" />
                <img src={alliance} alt="Logo 2" style={grayscaleStyle} className="w-[100px] h-auto my-auto" />
                <img src={mp} alt="Logo 3" style={grayscaleStyle} className="w-[100px] h-auto my-auto" />
                <img src={sp} alt="Logo 4" style={grayscaleStyle} className="w-[100px] h-auto my-auto" />
                <img src={aep} alt="Logo 5" style={grayscaleStyle} className="w-[100px] h-auto my-auto" />
                <img src={ip} alt="Logo 6" style={grayscaleStyle} className="w-[100px] h-auto my-auto" />
              </div>
            </div>
          </div>
          <div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name" className="block text-purple-800">Name*</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-purple-400" required />
              </div>
              <div>
                <label htmlFor="email" className="block text-purple-800">Email Address*</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-purple-400" required />
              </div>
              <div>
                <label htmlFor="phone" className="block text-purple-800">Phone Number*</label>
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-purple-400" required />
              </div>
              <div>
                <label htmlFor="company" className="block text-purple-800">Company Name</label>
                <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-purple-400" />
              </div>
              <div>
                <label htmlFor="message" className="block text-purple-800">Message</label>
                <textarea id="message" name="message" value={formData.message} onChange={handleChange} rows="4" className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-purple-400"></textarea>
              </div>
              <div className="text-right">
                <button type="submit" className="bg-purple-800 w-full text-white py-2 px-6 rounded-md hover:bg-purple-600 hover:text-white transition duration-300">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
