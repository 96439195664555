import React from 'react';

const BlogHeader = () => {
  return (
    <div>
      <section className="py-12 bg-[#00112D] flex flex-col mb-10 justify-center">
        <div className="px-4 py-16 mx-auto w-10/12">
          <div className="relative">
            <div className="">
              <p className="text-sm font-normal tracking-widest text-gray-300 uppercase" style={{ fontFamily: "Montserrat" }}>
                Welcome to the Blog of Godigitify Nexus
              </p>
              <h1 className="mt-6 text-4xl font-normal text-white sm:mt-10 sm:text-5xl lg:text-6xl xl:text-6xl">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                  Exploring Software Development at <br />
                </span>{' '}
                Godigitify Nexus
              </h1>
              <p className="max-w-lg mt-4 text-l font-normal text-gray-400 sm:mt-8">
                Stay updated on the latest trends, best practices, and innovative solutions in software development. Discover how Godigitify Nexus is driving success through cutting-edge technologies and expertise
              </p>
            
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogHeader;
