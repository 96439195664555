import React from 'react'
import img1 from '../../../../assets/Images/Tech/ar.jpg'
import img2 from '../../../../assets/Images/Tech/ai.jpg'
import img3 from '../../../../assets/Images/Tech/photoshop.png'
import imm from '../../../../assets/Images/Tech/digitalv.webp'

const Technologies = () => {
  return (
    <div>
      <section className="min-h-screen grid grid-cols-4 lg:grid-cols-12 gap-14 max-w-screen-xl mx-auto py-10 px-7 md:px-16 pt-20">

        <div className="col-span-4 lg:col-span-5 ">
          <div className="relative h-full">
            <img
              src={imm}
              alt="Digital"
              className="w-full rounded-xl shadow-md md:rounded-2xl md:shadow-lg h-48 md:h-72 lg:h-full object-cover"
            />
            <div className="absolute bottom-0 text-white bg-gradient-to-b from-transparent to-gray-700 rounded-2xl h-auto p-5 md:p-10">
              <span className="mb-2 inline-block text-sm font-light">
                Digital Design
              </span>
              <p className="text-sm leading-relaxed">
                From crafting strategic user-centric designs and innovative interactions to maintaining a consistent brand presence across platforms, our Digital Design services at GoDigitify ensure your brand communicates effectively, leaving a lasting impact on your digital audience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-4 lg:col-span-7 flex flex-col justify-center">
          <span className="inline-block mb-3 uppercase text-sm tracking-wide font-medium text-gray-900">
            Tools and Tecnology{" "}
          </span>
          <h1 className="text-4xl md:text-5xl md:leading-tight font-medium text-gray-900 xl:max-w-full">
            We Used Latest{" "}
            <span className="text-purple-900">Tools And Technology</span> for Digital Design
          </h1>
          <div className="grid gap-10 lg:max-w-xl pt-10 md:pt-14">
            <div className="sm:flex gap-6 items-center">
              <img
                src={img2}
                alt="Esther Howard"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                  AI-Powered Design Tools
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                  AI-driven design tools utilize machine learning algorithms to assist designers in automating repetitive tasks, generating design suggestions, and personalizing user experiences based on data insights.

                </p>
              </div>
            </div>
            <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
              <img
                src={img1}
                alt="Eleanor Pena"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                  3D and Augmented Reality (AR) Design
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                  Integrating 3D and AR technologies in digital design allows for the creation of immersive and interactive experiences. Designers can showcase products in 3D or overlay digital elements onto the real world.

                </p>
              </div>
            </div>
            <div className="sm:flex gap-6 items-center border-t border-gray-200 pt-12 sm:border-0 sm:pt-0">
              <img
                src={img3}
                alt="Dianner Russell"
                className="w-24 h-24 object-cover rounded-2xl flex-none"
              />
              <div>
                <span className="mb-2 inline-block text-sm font-light mt-5 sm:mt-0">
                  Photoshop
                </span>
                <p className="text-sm leading-relaxed font-semibold text-gray-900">
                  Embark on a visual journey where each design element is meticulously curated using Photoshop. We blend strategic thinking with visual aesthetics, ensuring that your graphics align seamlessly with your brand's goals.

                </p>
              </div>
            </div>
          </div>
          </div>
          </section>
          </div>

          );
};

          export default Technologies;
