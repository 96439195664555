import React, { useState } from 'react'
import contactImage from '../../src/assets/contact_.jpg'
import axios from 'axios';
const ContactBase = () => {

    const [formData, setFormData] = useState({
        name: '',
        interest: 'Interest in',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post('https://godigitify-nexus.onrender.com/contact/new', formData);
            console.log(response);


            if (response.status === 201) {
                console.log('Form submitted successfully!');
                alert('Form submitted successfully!');
            }
            else {
                console.log('Form submitted unsuccessfully!');
            }

        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    return (
        <div>
            <section class=" contact-bg text-gray-600 body-font relative md:px-20 px-5 lg:px-10 ">
                <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div class="lg:w-2/5 md:w-1/2  rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-center ">
                        <img src={contactImage} alt='' />

                    </div>
                    <form onSubmit={handleSubmit} class="lg:w-3/5 md:w-1/2 md:text-start text-center flex flex-col md:ml-auto w-full  md:py-8 mt-8 md:mt-0">
                        <div>
                            <div class="flex flex-col  w-full bg-white ">
                                <div className='flex'>
                                    <h2 class="text-lg text-[#4e286e] tracking-widest font-medium ml-5 lg:ml-0 title-font mb-1">Got a burning query</h2> <div className='lg:ml-3 invisible lg:visible bg-[#4e286e] mt-3 h-[2px] lg:w-[200px]'></div>
                                </div>
                                <h1 class="sm:text-5xl mt-2 text-2xl leading-9  title-font text-gray-900">Let’s discuss your project</h1>

                            </div>
                            <div class="flex pt-9 items-center w-full">
                                <div class="relative w-full lg:w-4/5">
                                    <select class="rounded border appearance-none w-full border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10">
                                        <option>Interest in</option>
                                        <option>Digital brand management</option>
                                        <option>Digital brand setup</option>
                                        <option>Website development</option>
                                        <option>Search engine optimization</option>
                                        <option>Brand video films</option>
                                    </select>
                                    <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className=" w-full lg:w-4/5 pt-5 flex flex-row justify-between gap-x-3 lg:gap-x-8 ">
                                <div class="  w-1/2 rounded-lg  flex flex-col     md:mt-0">

                                    <div class="relative w-full">
                                        <input type="text" id="full-name"
                                            name="name"
                                            placeholder="Your Name"
                                            value={formData.name}
                                            onChange={handleChange} class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                    <div class="relative w-full pt-5">
                                        <input type="email" id="email" placeholder='Your Email' name="email" value={formData.email}
                                            onChange={handleChange} class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>

                                </div>
                                <div class="  w-1/2 rounded-lg  flex flex-col    md:mt-0">

                                    <textarea id="message" name="message" placeholder='Write your requirements here....' value={formData.message}
                                        onChange={handleChange} class="w-full bg-white h-full rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  text-base outline-none text-gray-700  px-3  leading-6 transition-colors duration-200 ease-in-out"></textarea>

                                </div>
                            </div>
                            <div class="flex md:flex-row flex-col  w-full md:justify-start justify-center items-center pt-5">
                                <div class="relative mr-4 lg:w-full xl:w-1/2 ">
                                    <p class="md:text-base text-xs  text-gray-500 font-medium title-font mb-4 md:mb-1">DBM is your all-in-one solution! choose DBM</p>
                                </div>
                                <button type='submit' class=" lg:w-fit w-full  text-white bg-[#4e286e] px-6 border-0 py-2 focus:outline-none hover:bg-[#311944] rounded text-lg">Request A qoute </button>
                            </div>



                        </div>
                    </form>

                </div>

            </section>
        </div>
    )
}

export default ContactBase







