import React from 'react';
import { Link } from 'react-router-dom';
import bgimage from '../../assets/Backdrop/vector-2.png'
const Vision = () => {
  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="bg-[#00112d] h-[800px] py-8 flex items-center justify-center" style={{backgroundImage:`url(${bgimage})`, backgroundSize:"contain", backgroundPosition:"bottom center", backgroundRepeat:"no-repeat", backgroundBlendMode:'soft-light'}}>
      <div className="w-10/12 mx-auto flex flex-col md:flex-row items-center text-center md:text-left" >
        <div className="md:w-1/2 pr-8" >
          <h2 className="text-white lg:text-4xl md:text-3xl 3xl mb-4"><span className='text-purple-800'>Nexus</span> Innovate Vision</h2>
          <p className="text-white text-lg font-normal mb-4">
            Connecting Futures, Igniting Innovation, and Shaping Digital Excellence.
          </p>
        </div>

        <div className="md:w-1/2">
          <p className="text-white text-lg mb-4 font-normal px-4 md:px-12 text-justify">
            We envision a future where our efforts drive innovation and transformation. We aim to create a digital hub that not only meets today's needs but also anticipates tomorrow's challenges. Our goal is to support businesses in reaching their full potential, fostering growth, resilience, and shared success. Together, let's shape a future where every venture thrives.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-center">
            <div className=" just md:ml-3  invisible lg:visible bg-[#ffffff] mt-3 h-[1px] lg:w-[300px]"></div>
            <Link
            onClick={handleClick}
              to="/contact"
              className=" px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  hover:scale-110 hover:-translate-y-1 transition-transform duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 ring-1 ring-white rounded-2xl focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Get started
            </Link>

            {/* <a href="/" className="text-sm font-semibold leading-6 text-white">
                                Learn more <span aria-hidden="true">→</span>
                            </a> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
