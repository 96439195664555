import React from "react";
import {
  Web,
  PhoneAndroid,
  Code,
  Business,
  Cloud,
  Palette,
  LocalOffer,
  Description,
  Instagram,
  Visibility,
  Store,
  People
} from "@mui/icons-material"; // Import Material-UI icons

// Define your array of menu items
const navListMenuItems = [
  {
    title: "Web Development",
    description: "Create your online presence.",
    icon: <Web />, // Use Material-UI icon component
    link: "/webdevelopment",
  },
  {
    title: "Mobile Development",
    description: "Build powerful mobile apps.",
    icon: <PhoneAndroid />, // Use Material-UI icon component
    link: "/mobiledevelopment",
  },
  {
    title: "Software Development",
    description: "Custom software solutions for your business.",
    icon: <Code />, // Use Material-UI icon component
    link: "/softwaredevelopment",
  },
  {
    title: "Enterprise Solutions",
    description: "Scalable solutions for large organizations.",
    icon: <Business />, // Use Material-UI icon component
    link: "/enterprise",
  },
  {
    title: "Cloud Solutions",
    description: "Harness the power of cloud computing.",
    icon: <Cloud />, // Use Material-UI icon component
    link: "/cloudsolutions",
  },
  {
    title: "Graphic Design",
    description: "Bring your ideas to life with creative design.",
    icon: <Palette />, // Use Material-UI icon component
    link: "/graphicdesigning",
  },
  {
    title: "Logo Design",
    description: "Craft a unique and memorable brand identity.",
    icon: <LocalOffer />, // Use Material-UI icon component
    link: "/logodesigning",
  },
  {
    title: "Digital Design",
    description: "Design solutions for the digital world.",
    icon: <Description />, // Use Material-UI icon component
    link: "/digitaldesign",
  },
  // Add more menu items as needed
  // Add sub-services routes
  {
    title: "Branding Strategy",
    description: "Develop effective branding strategies.",
    icon: <Business />,
    link: "/brandingstrategy", // Assuming this is a route for branding strategy
  },
  {
    title: "Campaign Management",
    description: "Manage effective marketing campaigns.",
    icon: <Instagram />,
    link: "/campaignmanagement", // Assuming this is a route for campaign management
  },
  {
    title: "Online Reputation Management",
    description: "Manage your online reputation effectively.",
    icon: <Visibility />,
    link: "/orm",
},
{
    title: "E-commerce",
    description: "Build and manage your online store.",
    icon: <Store />,
    link: "/ecommerce",
},
{
    title: "Social Media Management",
    description: "Manage your social media presence.",
    icon: <People />,
    link: "/socialmedia",
},
  // Add more sub-services as needed
];

export default navListMenuItems;
