import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './keyfeatures.css';
import { MdAndroid, MdCloud, MdBuild, MdStorage } from 'react-icons/md'; // Import Material Icons
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const keyFeaturesData = [
  {
    icon: <MdAndroid />,
    title: 'Android Development',
    description: 'Craft dynamic and user-friendly Android applications tailored to your business needs. Harness the power of cutting-edge technologies to create immersive experiences on the world’s most popular mobile platform.',
    link: '/services',
  },
  {
    icon: <MdCloud />,
    title: 'Cloud Computing',
    description: 'Leverage the scalability, reliability, and flexibility of cloud computing solutions to transform your business operations. Harness the power of leading cloud platforms to drive innovation and growth.',
    link: '/services',
  },
  {
    icon: <MdBuild />,
    title: 'DevOps Solutions',
    description: 'Accelerate your software development lifecycle and improve collaboration between development and operations teams with robust DevOps solutions. Streamline processes, automate deployments, and enhance agility.',
    link: '/services',
  },
  {
    icon: <MdStorage />,
    title: 'Database Management',
    description: 'Optimize your database infrastructure for performance, reliability, and security. From data modeling to optimization, ensure efficient data management and unlock valuable insights for your business.',
    link: '/services',
  },
];

const KeyFeatures = () => {


  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleButtonClick = direction => {
    if (direction === 'prev') {
      sliderRef.current.slickPrev();
    } else if (direction === 'next') {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="key-features-slider-container mgh py-12">
      <div className="container">
        <p className='lg:text-5xl md:3xl sm:3xl text-4xl text-center font-medium'>
          <span className='text-purple-900'>Key</span> Findings
        </p>
      </div>
      <style>{'body { overflow-x: hidden; }'}</style>
      <Slider ref={sliderRef} {...settings}>
        {keyFeaturesData.map((slide, index) => (
          <Link to="/services" onClick={handleClick}>
          
          <div key={index} style={{ padding: '0 10px', height: '100%' }}>
            <div className="premium__slide slick-slide slick-current slick-active custom-height" style={{ width: '100%', height: '100%' }}>
              <div className="uar-section-box" style={{ height: '100%' }}>
                <div className="uar-inner-section-box opacity-80 hover:opacity-100" style={{ height: '100%' }}>
                  <div className="uar-section-content uar-grid-col-01">
                    <Link to={slide.link} tabIndex="0">
                      <div className="uar-icon">
                        {slide.icon}
                      </div>
                      <h2 className="uar-section-block-title">{slide.title}</h2>
                      <div className="uar-section-block-body">
                        <p style={{ color: "#000" }}>{slide.description}</p>
                      </div>
                    </Link>
                  </div>
                  <div className="uar-section-content uar-grid-col-02"></div>
                </div>
              </div>
            </div>
          </div>
          </Link>
        ))}
      </Slider>
      <div className="w-full m-auto mt-8">
        <div className="flex justify-center gap-10 ">
          <div
            className=" p-3 w-10 h-10 justify-center cursor-pointer bg-purple-900 hover:bg-white rounded-full flex item-center"
            onClick={() => handleButtonClick('prev')}
          >
            <FontAwesomeIcon icon={faArrowLeft} className='text-white hover:text-purple-900 ' />
          </div>
          <div
            className=" p-3 w-10 h-10 justify-center cursor-pointer bg-purple-900 hover:bg-white rounded-full flex item-center"
            onClick={() => handleButtonClick('next')}
          >
            <FontAwesomeIcon icon={faArrowRight} className='text-white hover:text-purple-900' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
