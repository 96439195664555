import React from 'react'
import BlogHeader from '../components/Blog/BlogHeader'
import AllBlogs from '../components/Blog/AllBlogs'
import Calltoaction from '../components/About/Calltoaction'

const Blogs = ({blogs}) => {
  return (
    <div>
      <BlogHeader/>
      <AllBlogs blogs={blogs}/>
      <Calltoaction/>
    </div>
  )
}

export default Blogs