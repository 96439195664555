import React from 'react'
import BlogHeader from '../components/Blog/BlogHeader'
import CreateBlog from '../components/Blog/CreateBlog'
import Calltoaction from '../components/About/Calltoaction'

const Create = () => {
  return (
    <div>
        <BlogHeader/>
        <CreateBlog/>
        <Calltoaction/>
    </div>
  )
}

export default Create