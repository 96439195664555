import React, { useState } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/Images/arrow.png";
import "../../styling/services.css";

function Section() {

  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const card1 = [
    {
      id: 1,
      title: "Custom Software Development",
      content:
        "Leverage our expertise to build bespoke software solutions tailored to your unique business requirements. Whether you need a web application, mobile app, or desktop software, we've got you covered.",
      path: "/softwaredevelopment",
    },
   
      {
        id: 2,
        title: "Web Development",
        content:
          "Our skilled web developers specialize in creating responsive and scalable websites that provide an exceptional user experience. From e-commerce platforms to content management systems, we deliver solutions that align with your business goals.",
        path: "/webdevelopment",
      },
      {
        id: 3,
        title: "Mobile App Development",
        content:
          "Stay connected with your audience on the go. Our mobile app development services cover iOS, Android, and cross-platform applications. We focus on creating intuitive and feature-rich apps that enhance user engagement.",
        path: "/mobiledevelopment",
      },
      {
        id: 4,
        title: "Enterprise Software Solutions",
        content:
          "Streamline your business operations with our enterprise software solutions. From CRM systems to ERP software, we develop robust tools that optimize workflows, improve efficiency, and drive overall productivity.",
        path: "/enterprise",
      },
      {
        id: 5,
        title: "Cloud Solutions",
        content:
          "Embrace the power of the cloud with our cloud computing services. We design, deploy, and manage cloud-based solutions that offer scalability, security, and accessibility, ensuring your business stays ahead in the digital era.",
        path: "/cloudsolutions",
      },
      {
        id: 6,
        title: "Graphic Design",
        content:
          "Immerse your brand in captivating visuals crafted by our skilled graphic designers. From logos and brochures to social media posts and marketing collateral, we infuse creativity and strategic thinking into every design element.",
        path: "/graphicdesigning",
      },
      {
        id: 7,
        title: "Logo Design",
        content:
          "Your logo is the cornerstone of your brand identity. Our designers work collaboratively with you to create a logo that reflects your brand's personality, values, and uniqueness, leaving a memorable mark in the minds of your audience.",
        path: "/logodesigning",
      },
      {
        id: 8,
        title: "Branding Strategy",
        content:
          "Elevate your brand with a comprehensive branding strategy. We delve deep into understanding your business, target market, and competition to create a unique brand identity that sets you apart. Our strategies encompass visual elements, messaging, and overall brand experience.",
        path: "/brandingstrategy",
      },
      {
        id: 9,
        title: "Digital Design",
        content:
          "Navigate the digital landscape with eye-catching digital designs. Our team specializes in creating engaging graphics for websites, social media, and online marketing campaigns, ensuring your brand stands out in the digital realm.",
        path: "/digitaldesign",
      },
      {
        id: 10,
        title: "Campaign Management",
        content:
          "Boost brand visibility and drive conversions with our targeted Campaign Management services. From concept to execution, we optimize every aspect, ensuring measurable ROI.",
        path: "/campaignmanagement",
      },
      {
        id: 11,
        title: "ORM (Online Reputation Management)",
        content:
          "Control your online narrative. Our ORM services safeguard and enhance your brand reputation through proactive monitoring and strategic communication.",
        path: "/orm",
      },
      {
        id: 12,
        title: "Social Media Management",
        content:
          "Navigate social media seamlessly. Our services craft a cohesive strategy, driving brand loyalty through content creation, engagement, and analytics.",
        path: "/socialmedia",
      },
      {
        id: 13,
        title: "E-commerce Store Development",
        content:
          "Transform your online storefront into a thriving E-commerce powerhouse. Our end-to-end solutions optimize user experience and boost conversion rates.",
        path: "/ecommerce",
      },
    ];
    // Add other card objects here...
  

  return (
    <div>
      <div className="container flex flex-col mx-auto bg-transparent text-left">
        <h3 className="text-4xl text-gray-800 pb-10 text-center font-semibold">Digital Amenities within the GoDigitify Nexus Platform</h3>
        <hr className="w-500 pb-10"/>
        <div className="w-full draggable">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 w-full p-4 m-auto">
            {card1.map((card, index) => (
              <div
                key={index}
                className="cards-ser oph"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Link to={card.path} onClick={handleClick} className="block max-w-sm p-6 bg-transparent">
                  <h5 className="mb-2 text-2xl font-medium txt-col">{card.title}</h5>
                  <div className="vl" />
                  <p className="font-normal pp txt-col">{card.content}</p>
                  <img
                    src={image}
                    alt="Your Image Alt Text"
                    style={{ height: "30px", marginTop: "20px" }}
                    className={`arrowp ${hoveredIndex === index ? "show" : ""}`}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
