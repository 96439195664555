import React from 'react'

const Number = () => {
    return (
        <div>
            <div className="container bg-gradient-to-r from-violet-500 to-violet-300 ... py-24 sm:py-32 mx-auto rounded-3xl  mb-10 mt-5 shadow-lg shadow-gray-500/50 ">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-[#FFFFFF]">
                                Active Clients
                            </dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-[#FFFFFF] sm:text-5xl">
                                5+
                            </dd>
                        </div>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-[#FFFFFF]">
                                Repeat Customers
                            </dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-[#FFFFFF] sm:text-5xl">
                                85%
                            </dd>
                        </div>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-[#FFFFFF]">
                                New users annually
                            </dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-[#FFFFFF] sm:text-5xl">
                                10,000+
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default Number
