import React from 'react'
// import '../styling/home.css'
import HeroBannerSlider from '../components/LandingPageComponents/HeroBannerSlider'
import FeaturedStorySlider from '../components/LandingPageComponents/FeaturedStorySlider'
import LandingPageStories from '../components/LandingPageComponents/LandingPageStories'
import LandingPageStoriesCaraousel from '../components/LandingPageComponents/LandingPageStoriesCaraousel'
// import LandingHero from '../components/LandingPageComponents/LandingHero'
import Vision from '../components/About/Vision'
import DemoBlog from '../components/LandingPageComponents/DemoBlog'
import Description from '../components/Service/Description'
import Calltoaction from '../components/About/Calltoaction'
import ShortDesc from '../components/LandingPageComponents/ShortDesc'
import Industry from '../layout/Indrusty'
import KeyFeatures from '../components/LandingPageComponents/KeyFeatures'
import TalesofTransformation from '../components/LandingPageComponents/TalesofTransformation'
import LandingPageModal from '../components/LandingPageComponents/LandingPageModal'
import CommunitySection from '../components/LandingPageComponents/CommunitySection'
import RecogsAwards from '../components/LandingPageComponents/RecogsAwards'
import Testimonials from '../components/About/Testimonials'
import TestiOld from '../components/About/TestiOld'
import GetInTouch from '../components/LandingPageComponents/GetInTouch'
const Home = ({blogs}) => {
  return (
   <>
    <HeroBannerSlider />
    <Vision/>
    <ShortDesc/>
    <FeaturedStorySlider />
    <Industry/>
    <KeyFeatures/>
    <LandingPageStories/>
    <DemoBlog blogs={blogs}/>
 
    <Testimonials/>
    <RecogsAwards/>
    <LandingPageModal/>
    {/* <CommunitySection/> */}
  
    {/* <LandingPageStoriesCaraousel/> */}
    <GetInTouch/>

   </>
  )
}

export default Home 