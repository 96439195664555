import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          At GoDigitify, we're dedicated to shaping purpose-driven digital experiences through our leading service – Innovative App Development.

        </h2>
        <p className="pt-10">
          <strong>Purpose-Driven Development</strong> <br />
          Embark on a digital journey with us, crafting each app with a unique purpose. We go beyond aesthetics, aiming to forge deeper connections between your brand and your audience.

        </p>
        <p className="pt-4">
          <strong>Innovation at Your Fingertips</strong> <br />
          Innovation is our forte. Our app development service integrates cutting-edge technologies, intuitive design, and forward-thinking functionalities to create solutions that stand out.

        </p>
        <p className="pt-4">
          <strong>Engagement Redefined</strong> <br />
          Engagement is fundamental to our approach. From immersive features to seamless interactions, we prioritize creating apps that captivate and connect with your users on a profound level.

        </p>
      </div>
    </div>
  )
}

export default Description
