import React from 'react';

const CareerShaping = () => {
  const data = [
    {
      heading: 'Innovative Solutions, Today:',
      brief: "At the heart of Godigitify Nexus is innovation. We don't wait for tomorrow; we innovate today. Our team is driven by the spirit of creating solutions that anticipate future needs, ensuring we stay ahead of the curve in the ever-evolving digital world.",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-6 h-6"
          viewBox="0 0 24 24"
        >
          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
        </svg>
      )
    },
    {
      heading: 'Digital Excellence, Redefined:',
      brief: "The future demands excellence, and at Godigitify Nexus, we set the bar high. We strive not just to meet but to exceed expectations, constantly raising the standards of digital excellence. Join us in the journey of setting new benchmarks and redefining what's possible.",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-6 h-6"
          viewBox="0 0 24 24"
        >
          <path d="M10 3L21 12 10 21 3 12 10 3z"></path>
        </svg>
      )
    },
    {
      heading: 'Collaboration for Collective Success:',
      brief: "The company of tomorrow is built on collaboration. We believe that collective success is the key to enduring growth. At Godigitify Nexus, every team member is a valued contributor, and together, we forge a path towards a future where collaboration is the cornerstone of success.",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-6 h-6"
          viewBox="0 0 24 24"
        >
          <path d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      )
    },
    {
      heading: 'Investing in Your Future:',
      brief: "Your growth is our priority. At Godigitify Nexus, we invest in our people, providing the tools and opportunities for continuous learning and development. Join us, and let's build a future where your potential finds its fullest expression.",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-6 h-6"
          viewBox="0 0 24 24"
        >
          <path d="M13 10V3L4 14h7v7l9-11h-7z"></path>
        </svg>
      )
    },
    {
      heading: 'Agility in Adapting:',
      brief: "Tomorrow's company is agile, ready to adapt to the changing landscape. Godigitify Nexus embraces flexibility and adaptability, ensuring that we not only keep up with change but lead the way in navigating the challenges and opportunities of tomorrow.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>
      )
    },
  ];

  return (
    <>
      <section className="text-gray-600 body-font px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="container px-5 py-24 mx-auto">
          <h1 className="sm:text-3xl md:text-4xl text-2xl pb-8 font-medium title-font text-center text-gray-900 mb-20">
          Building Tomorrow's Success
          </h1>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            {data.map((item, index) => (
              <div key={index} className="p-4 md:w-1/3 flex">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-[#00112D] text-white mb-4 flex-shrink-0">
                  {item.icon}
                </div>
                <div className="flex-grow pl-6">
                  <h2 className="text-xl  title-font font-semibold mb-4">{item.heading}</h2>
                  <p className="leading-relaxed text-base">{item.brief}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerShaping;

