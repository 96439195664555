import React from 'react'
import Hero from '../../components/Service/Servicesub/cloudssolutions/Hero'
import Description from '../../components/Service/Servicesub/cloudssolutions/Description'
import Technologies from '../../components/Service/Servicesub/cloudssolutions/Technologies'
import Number from '../../components/Service/Servicesub/cloudssolutions/Number'
const Cloudsolutions = () => {
  return (
    <div>
         <Hero />
            <Description />
            <Technologies />
            <Number />
    </div>
  )
}

export default Cloudsolutions
