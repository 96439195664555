import React from 'react'
import Hero from '../../components/Service/Servicesub/appdevelopment/Hero'
import Description from '../../components/Service/Servicesub/appdevelopment/Description'
import Technologies from '../../components/Service/Servicesub/appdevelopment/Technologies'
import Number from '../../components/Service/Servicesub/appdevelopment/Number'
const MobileAppdev = () => {
  return (
    <div>
      <Hero />
            <Description />
            <Technologies />
            <Number />
    </div>
  )
}

export default MobileAppdev
