import React from "react";
import "../../styling/services.css";

const description = () => {
  return (
    <div>
      <div className="container">
        <h4 className="pt-10 text-3xl font-semibold"><span className="text-purple-900">Godigitify Nexus</span>: Where Code Meets Innovation for Limitless Business Transformation
        </h4>
        <p className="text-justify pt-5  pb-40  text-md text-[#333333]" id="des-text">
          Welcome to Godigitify Nexus, your gateway to a realm where software
          solutions transcend traditional boundaries. At Godigitify Nexus, we
          pride ourselves on crafting exceptional and tailor-made software
          solutions that are not just lines of code but strategic assets
          propelling your business forward. Our commitment to innovation goes
          beyond the ordinary, ensuring that every solution seamlessly
          integrates cutting-edge technology and strategic thinking. Embark on a
          transformative journey with us as we navigate the digital landscape
          together. At Godigitify Nexus, your business isn't just beyond code
          and boundaries; it's at the nexus of innovation and growth. Experience
          the synergy of our dedicated team and cutting-edge technology, and
          let's propel your business into a future where
          possibilities are limitless.
        </p>
      </div>
    
    </div>
  );
};

export default description;
