import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ashwanisir from '../../assets/testimonials/ashwani-sir.webp'
import ashokisir from '../../assets/testimonials/ashok-sir.webp'
import nikhil from '../../assets/testimonials/nikhil.png'
import bhangu from '../../assets/testimonials/13SB.jpg'
import nirmalsir from '../../assets/testimonials/nirmalsir.png'

import sviet from '../../assets/Clients/1.png'
import alliance from '../../assets/Clients/ais.png'
import sb from '../../assets/Clients/sb.png'
import mp from '../../assets/Clients/9.png'
import sp from '../../assets/Clients/4.png'
import aep from '../../assets/Clients/AEP Logo.png'

const testimonials = [
  {
    name: "Mr. Ashwani Garg",
    position: "Chairperson of SVGOI and also a prominent chain of school",
    text: "Exceptional software development and digital marketing services! Your company's expertise in both areas has been invaluable to our organization. From streamlined workflows to versatile marketing strategies, you've provided comprehensive solutions that drive results.",
    image: ashwanisir,
    organizationImage: sviet,
  },
  {
    name: "Mr. Ashok Garg",
    position: "President of a prominent chain of schools in the Punjab Region",
    text: "Solid foundation for our projects! Your team has laid a strong groundwork for our digital presence and software initiatives. The comprehensive support you offer for database-centric projects is commendable.",
    image: ashokisir,
    organizationImage: alliance,
  },
  {
    name: "Mr. Sukhjinder Bhangu",
    position: "CEO of 13SB Cleaning, Canada",
    text: "Ideal partner for digital innovation! Your company's services are perfect for businesses like ours with database needs. The comprehensive toolkit you provide is tailored for software development companies like ours, enabling us to build scalable digital solutions.",
    image: bhangu,
    organizationImage: sb
  },
  {
    name: "Mr Venkatesh Bharti",
    position: "Director of an IP Solutions Company",
    text: "Seamless integration for our projects! Your solutions offer a hassle-free experience, making you the preferred choice for companies dealing with digital challenges. Your organized structure and theming capabilities alleviate common pain points associated with digital projects.",
    organizationImage: mp

  },
  {
    name: "Mr. Nikhil Gandhi",
    position: "Principal of one of prominent School of Punjab Region",
    text: "Enhanced workflow for digital projects! Your toolkit provides a wide array of pre-designed components and pages suitable for our projects. As a software architect, I find your services to be a perfect fit for our digital development needs.",
    image: nikhil,
    organizationImage: sp
  },
  {
    name: "Mr. Nirmal Sinha",
    position: "Director of a Top Notch Educational Organization",
    text: "Impressed with the seamless integration. Your company offers an exceptionally well-crafted set of solutions that seamlessly combine the best of digital tools and strategies. As a developer at our organization, I find your services to be an invaluable resource.",
    image: nirmalsir,
    organizationImage: aep
  },
];

const Testimonials = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl">Trusted By Our Clients <span className='text-purple-800'> Globally</span></p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">Lets hear it from some of the eminent personalities</p>
        </div>
        <div className="mt-10 relative">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                name={testimonial.name}
                position={testimonial.position}
                text={testimonial.text}
                image={testimonial.image}
                orgImage={testimonial.organizationImage}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <div className="slick-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#7F1DFF"
      className="h-6 w-6"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
);
  
const PrevArrow = ({ onClick }) => (
  <div className="slick-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#7F1DFF"
      className="h-6 w-6 transform rotate-180"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
);

const TestimonialCard = ({ name, position, text, image, orgImage }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6 h-[400px] flex flex-col justify-between">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img className="h-12 w-12 rounded-full" src={image} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-lg font-bold text-gray-900">{name}</p>
            <p className="text-sm text-purple-800">{position}</p>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-base text-gray-700">{text}</p>
        </div>
        <div className='flex justify-end'>
          <img src={orgImage} className='right-0 h-[35px]' alt="" srcset="" />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
