import React from 'react'

const Description = () => {
  return (
    <div>
      <div className="container mx-auto max-w-8xl">
        <h2 className="pt-20 text-3xl capitalize">
          Ready to elevate your social media game? Dive into the world of digital success with GoDigitify.
        </h2>
        <p className="pt-10">
          <strong>Tailored Social Media Strategies </strong> <br />
          Whether you're a startup or an established brand, we customize our social media solutions to fit your unique needs, driving engagement and fostering community.
        </p>
        <p className="pt-4">
          <strong>Seamless Content Creation</strong>  <br />
          Say goodbye to content headaches! Our team crafts captivating posts and visuals that resonate with your audience, keeping them hooked and coming back for more.
        </p>
        <p className="pt-4">
        <strong>Mobile-Optimized Presence</strong>  <br />
        With mobile usage soaring, we ensure your social media profiles shine on every device. From smartphones to tablets, we've got you covered!
        </p>
      </div>
    </div>
  )
}

export default Description
