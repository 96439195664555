import React, { useState, useEffect } from "react";
import './herostyle.css';
import backdrop1 from '../../assets/Backdrop/1..jpg'
import ban2 from '../../assets/ban2.jpeg';
import ban3 from '../../assets/ban3.jpeg';
import ban4 from '../../assets/ban4.jpeg';
import { Link } from "react-router-dom";
import bg1 from '../../assets/Backdrop/Untitled design.gif'
import bg2 from '../../assets/Backdrop/Untitled design (2).gif'
import bg3 from '../../assets/Backdrop/Untitled design (3).gif'
import bg from '../../assets/Backdrop/Untitled design (1).gif'

const HeroBannerSlider = () => {
  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const slidesData = [
    {
      category: "#ElevateWithExcellence",
      title: "Godigitify Nexus:Where Digital Dreams Take Flight",
      description: " ",
      imageUrl: bg1,
      link: "/about-us"
    },
    {
      category: "#DigitalAlchemy ",
      title: "Your Gateway to Digital Marvels and Creative Wonders",
      description: "",
      imageUrl: bg3,
      link: "/about-us"
    },
    {
      category: "#CraftingDigitalExperiences",
      title: "Embark on a Digital Odyssey with Godigitify Nexus's Creative Flair",
      description: "",
      imageUrl: bg1,
      link: "/about-us"
    },
    {
      category: "#NexTechMastery",
      title: "Navigating the Digital Realm with Unmatched Creativity  ",
      description: "",
      imageUrl: bg2,
      link: "/about-us"
    },
   
  ];

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 6000);

    // Clear previous interval when the component is unmounted or the effect is re-run
    return () => clearInterval(intervalId);
  }, [currentSlideIndex]);

  const setActiveSlide = (index) => {
    setCurrentSlideIndex(index);
  };

  const updatePagination = (index) => {
    const paginationBlocks = document.querySelectorAll(".slide-pagination-block");
    paginationBlocks.forEach((block, i) => {
      block.classList.toggle("active-slide-pagination-block", i === index);
    });
  };

  const goToSlide = (index) => {
    setActiveSlide(index);
    updatePagination(index);
  };

  const goToPrevSlide = () => {
    const newIndex = (currentSlideIndex - 1 + slidesData.length) % slidesData.length;
    goToSlide(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = (currentSlideIndex + 1) % slidesData.length;
    goToSlide(newIndex);
  };

  return (
   <div className="hero-banner">

      <section className="hero-banner-slider-section">
        <div id="heroBannerSliderID" className="hero-banner-slider">
          <div className="hero-banner-slides-wrapper">
            {slidesData.map((slide, index) => (
              <div
                key={index}
                className={`hero-banner-slide ${index === currentSlideIndex ? 'active-slide' : ''}`}
                data-slide-index={index}
                style={{ backgroundImage: `url(${slide.imageUrl})`}}
              >
                <div className="hero-banner-asset brand-02-bckg">
                  <div key={index} className={`hero-banner-slide ${index === currentSlideIndex ? 'active-slide' : ''}`} data-slide-index={index} style={{ backgroundImage: `url("${slide.imageUrl}")`, backgroundRepeat:'no-repeat', backgroundSize:'cover'  }}>
                    <div className="row-fluid page-center page-pad">
                      <div className="span12">
                        <div className="hero-slider-content">
                          <h5 className="brand-03 hero-slider-category">{slide.category}</h5>
                          <div className="row-fluid">
                            <div className="span7 hero-slider-title">
                              <h1 className="mm big-h1 brand-03">{slide.title}</h1>
                            </div>
                          </div>
                          <div className="row-fluid">
                            <div className="span5 body-text brand-03 hero-slider-desc">{slide.description}</div>
                          </div>
                          
                           <button className='dark-color-bg'><Link onClick={handleClick}
                  to={slide.link}
                  className="inline-block px-6 py-3 bg-primary text-white rounded-full hover:bg-opacity-80 transition duration-300"
                >
                  Know more
                </Link>
                </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Your pagination components go here */}
          <ul className="slider-pagination-section">
            <li className="xs-slide-pagination xs-slide-pagination-left brand-03 small-text">
              <span className="xs-pagination-number">{currentSlideIndex + 1}</span>
              <span>/</span>
              <span>{slidesData.length}</span>
            </li>
            {slidesData.map((slide, index) => (
              <li
                key={index}
                data-pagination-index={index}
                className={`slide-pagination-block ${index === currentSlideIndex ? 'active-slide-pagination-block' : ''}`}
                onClick={() => goToSlide(index)}
              >
                <div className="slide-pagination-number">{index + 1}</div>
                <div className="slide-pagination-text">{slide.category}</div>
              </li>
            ))}
            <li className="xs-slide-pagination xs-slide-pagination-right brand-03">
              <div className="small-print-text xs-next-slide" id="nextSlideControl">
                next
              </div>
              <div className="small-text xs-pagination-text">{slidesData[currentSlideIndex + 1]?.category}</div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default HeroBannerSlider;
